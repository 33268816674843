<section [ngStyle]="{'background-color':gridOptions.bgColor ? gridOptions.bgColor: ''}">
    <div class="bg-image-container" *ngIf="gridOptions.bgImg"
        [ngStyle]="{'background-image':'url(assets/images/'+ gridOptions.bgImg +')'}"></div>
    <div class="data-table" [ngClass]="gridOptions.class ? gridOptions.class : ''">
        <div class="text-center" *ngIf="gridOptions.title && !gridOptions.enableSearch">
            <h2 [innerHTML]="gridOptions.title"></h2>
            <hr class="title-divider">
        </div>
        <div class="row align-items-center mt-3 mb-2 d-block d-md-flex">
            <div class="col" *ngIf="gridOptions.title && gridOptions.enableSearch">
                <h4 class="m-0 d-inline-block">
                    {{(gridOptions.title ? gridOptions.title: 'gridTitle is undefined') | titlecase}}</h4>
                <h6 class="ml-1 d-inline-block align-middle" *ngIf="filteredData.length">
                    <small>(</small>{{filteredData.length}}<small>)</small></h6>
            </div>
            <div class="col d-flex justify-content-md-end justify-content-between align-items-center">

                <form class="input-group-form-inside input-group-form-inside-both align-self-end"
                    [formGroup]="fullSearchForm" *ngIf="gridOptions.enableSearch">
                    <div class="form-group input-group input-group-prepend-right m-0">
                        <div class="input-group-prepend">
                            <span class="btn"><i class="fa fa-search"></i></span>
                        </div>
                        <ng-container *ngIf="!gridOptions.localSearch">
                            <input type="text" class="form-control input-search" placeholder="search.."
                                name="liveSearch" formControlName="liveSearch">
                            <div class="input-group-append">
                                <span class="btn" *ngIf="fullSearchForm.get('liveSearch').value"
                                    (click)="fullSearchForm.controls.liveSearch.setValue('')"><i
                                        class="fa fa-times"></i></span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="gridOptions.localSearch">
                            <input type="text" class="form-control input-search" placeholder="search.."
                                name="fullSearch" formControlName="fullSearch">
                            <div class="input-group-append">
                                <span class="btn" *ngIf="fullSearchForm.get('fullSearch').value"
                                    (click)="fullSearchForm.controls.fullSearch.setValue('')"><i
                                        class="fa fa-times"></i></span>
                            </div>
                        </ng-container>
                    </div>
                </form>
                <div class="d-flex" *ngIf="gridOptions.enableFilter">
                    <button *ngIf="gridOptions.enableSearch" class="btn btn-primary mr-2 btn-search"
                        title="Click to search in DB" (click)="liveDbSearch()">
                        <i class="fa fa-search"></i>
                    </button>
                    <button class="btn btn-primary mx-2" title="Enable filter" (click)="enableFilter = !enableFilter">
                        <i class="fa fa-sliders"></i>
                    </button>
                </div>
            </div>

        </div>

        <div class="table-responsive">
            <table class="table border mb-0 {{gridOptions.tableClass}}">

                <thead>
                    <tr>
                        <th *ngFor="let key of gridOptions.colDefs"
                            class='text-truncate first-letter-capital cursor-pointer' (click)="sortTable(key.name, '')"
                            [ngStyle]="{'width': key.width+'px'}"
                            [innerHTML]="key.displayName =='id'? (key.displayName | uppercase) : key.displayName">
                        </th>
                    </tr>
                    <tr *ngIf="gridOptions.enableFilter && enableFilter" class="row-filter">
                        <td class="p-0"
                            *ngIf="gridOptions.enableRowSelect || gridOptions.enableRowRemove || gridOptions.enableRowEdit">
                        </td>
                        <td *ngFor="let key of gridOptions.colDefs | slice:0:gridOptions.colDefs.length;let k = index">
                            <div class="input-group-form-inside input-group-form-inside-both"
                                *ngIf="key.enableFilter != false" [formGroup]="columnSearchForm">
                                <div class="form-group input-group input-group-prepend-right m-0">
                                    <input type="text" class="px-2 py-1 form-control h-auto rounded"
                                        placeholder="searh.." [name]="key.search" [formControlName]="key.name"
                                        (input)="getFilteredData(key.name, $event.target.value)">
                                    <div class="input-group-append">
                                        <span class="btn" *ngIf="columnSearchForm.value[key.name]"
                                            (click)="columnSearchForm.controls[key.name].setValue('');getFilteredData(key.name, key.search)"><i
                                                class="fa fa-times"></i></span>
                                    </div>
                                </div>
                            </div>
                        </td>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of filteredData | completeSearch: fullSearchForm.value.fullSearch | paginate: config;let i = index"
                        [ngClass]="{'table-danger':row.selected}">
                        <ng-container
                            *ngFor="let col of gridOptions.colDefs | slice:0:gridOptions.colDefs.length;let j = index">
                            <td class="text-truncate"
                                [ngClass]="{'py-1 align-middle text-center':col.type=='img', 'p-0':col.type=='link', 'merged-cells': row.colspan}"
                                [attr.colspan]="row.colspan">

                                <div class="plain-text" *ngIf="col.type == null || col.type==''"
                                    [innerHTML]="row[col.name] | safeHtml">
                                </div>

                                <div *ngIf="col.type=='date'"
                                    [innerHTML]="(row[col.name] !='0000-00-00 00:00:00' ? row[col.name]:'') | date:'dd-MM-y'">
                                </div>

                                <div *ngIf="col.type == 'currency'" [innerHTML]="row[col.name] | currency"></div>

                                <div *ngIf="col.type == 'password'">************</div>

                                <div *ngIf="col.type == 'img'"
                                    class="d-inline-block ratio-default tooltip-img-conatiner"
                                    [ngClass]="{'ratio-one-one':col.imgRatio == '1:1', 'ratio-two-one':col.imgRatio == '2:1', 'ratio-three-two':col.imgRatio == '3:2', 'ratio-four-three':col.imgRatio == '4:3', 'ratio-sixteen-nine':col.imgRatio == '16:9'}"
                                    tabindex="0" data-trigger="focus" data-toggle="tooltip" data-html="true"
                                    title='<div class="tooltip-img"><img src="{{assetUrl}}/images/{{col.imgLocation}}/{{row[col.name]}}" class="img-fluid"/></div>'>
                                    <img *ngIf="row[col.name] && row['type'] != 'video'"
                                        [src]="assetUrl+'/images/'+col.imgLocation+'/'+row[col.name]" class="img-fluid">
                                    <img *ngIf="row[col.name] && row['type'] == 'video'" [src]="row[col.name]"
                                        class="img-fluid">
                                    <div *ngIf="!row[col.name]" class="fa text-30"
                                        [ngClass]="col.imgIcon? col.imgIcon: 'fa-picture-o'"></div>
                                </div>

                                <div *ngIf="col.type == 'img-video'" class="d-inline-block ratio-default"
                                    [ngClass]="{'ratio-one-one':col.imgRatio == '1:1', 'ratio-two-one':col.imgRatio == '2:1', 'ratio-three-two':col.imgRatio == '3:2', 'ratio-four-three':col.imgRatio == '4:3', 'ratio-sixteen-nine':col.imgRatio == '16:9'}"
                                    tabindex="0" data-trigger="focus" data-toggle="tooltip" data-html="true"
                                    title='<div class="tooltip-img"><img src="{{assetUrl}}/images/{{col.imgLocation}}/{{row[col.name]}}" class="img-fluid"/></div>'>
                                    <img *ngIf="row[col.name] && row.type == 'image'"
                                        [src]="assetUrl+'/images/'+col.imgLocation+'/'+row[col.name]" class="img-fluid">
                                    <img *ngIf="row[col.name] && row.type == 'video'" [src]="row[col.name]"
                                        class="img-fluid">
                                    <div *ngIf="!row[col.name]" class="fa text-30"
                                        [ngClass]="col.imgIcon? col.imgIcon: 'fa-picture-o'"></div>
                                </div>

                                <span *ngIf="col.type == 'file'"><a
                                        href="/assets/{{col.fileLocation}}/{{row[col.name]}}" target="_blank">
                                        {{row[col.name] }}</a></span>

                                <div *ngIf="col.type=='link'"><a
                                        [routerLink]="[row[col.name] ? row[col.name] : col.staticData, row.id]"
                                        class="btn btn-sm btn-primary"
                                        [innerHTML]="row[col.name] ? row[col.name] : col.template">
                                    </a>
                                </div>

                                <div class="plain-text" *ngIf="col.type == 'object' && row[col.name].length>0">
                                    <div *ngIf="col.name == 'attachments'">
                                        <div *ngFor="let rowRow of row[col.name];let last = last">{{rowRow['fileName']}}
                                            {{!last?', ':''}}</div>
                                    </div>
                                </div>

                            </td>
                        </ng-container>

                    </tr>
                    <tr *ngIf="filteredData.length==0">
                        <td class="text-center" [attr.colspan]="gridOptions.colDefs.length+1">No data found!</td>
                    </tr>
                    <tr *ngIf="filteredData.length > 0 && ((filteredData | completeSearch:fullSearch).length == 0)">
                        <td class="text-center" [attr.colspan]="gridOptions.colDefs.length+1">No data found!</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="text-center mt-3"
            *ngIf="gridOptions.enablePagination && (filteredData | completeSearch:fullSearch).length != 0">
            <div class="d-inline-block w-auto mr-2 mw-100" [formGroup]="paginationForm">
                <input type="number" min="1" max="100" class="form-control small" placeholder="Page"
                    title="Items per page" formControlName="itemsPerPage"
                    (keyup)="changeItemsPerPage(paginationForm.value.itemsPerPage)">
            </div>
            <pagination-controls #pagination (pageChange)="pageChange($event)"
                class="mt-3 justify-content-center d-inline-block"></pagination-controls>
        </div>
    </div>
</section>