<section [ngStyle]="{'background-color':data.bgColor ? data.bgColor: '#178784'}">
        <div class="bg-image-container" *ngIf="data.bgImg" [ngStyle]="{'background-image':'url(assets/images/'+ data.bgImg +')'}"></div>
        <div [ngClass]="data.class ? data.class : 'container-sm'">
            <div class="row align-items-center">
                <div class='col-md-7 promo-banner pb-4 pb-md-0 offset-md-1 text-center text-md-left'>
                    <div [innerHTML]="data.body | safeHtml"></div>
                    <a class="btn btn-primary mt-3" routerLink="/sign-up">Get started</a>
                </div>
                <div class="col-md-3 text-center" *ngIf="data.img">
                    <a class="text-light" routerLink="/schedule-demo/">
                        <img [src]="'assets/images/'+data.img" class="img-fluid center-block top5"
                            alt="RCM ipad">
                    </a>
                </div>
            </div>
        </div>
</section>