<div *ngIf="data" [ngClass]="data.class ? data.class : ''">
    <div class="title mb-4" *ngIf="data.title">
        <h2 [innerHTML]="data.title"></h2>
        <hr class="title-divider">
    </div>

    <form [formGroup]="ngForm" (ngSubmit)="onSubmit()" class="floating-label-form">
        <div class="row">
            <div *ngFor="let field of data.formFields; let i = index"
                [ngClass]="data.columns == 2 ? 'col-md-6' : data.columns == 3 ? 'col-md-4' : 'col-md-12'">
                <div class="form-group">
                    <label [ngClass]="{'show': ngForm.get(field.controlName).value}" [innerHTML]="field.label"></label>
                    <div class="input-group">
                        <ng-container [ngSwitch]="field.type">
                            <input *ngSwitchCase="'text'" [type]="field.type" class="form-control"
                                [placeholder]="field.label" [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required">

                            <input *ngSwitchCase="'number'" [type]="field.type" class="form-control"
                                [placeholder]="field.label" [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required">

                            <input *ngSwitchCase="'email'" [type]="field.type" class="form-control"
                                [placeholder]="field.label" [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required">

                            <select *ngSwitchCase="'dropdown'" class="form-control"
                                [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required">
                                <option value="" [innerHTML]="field.label"></option>
                                <option *ngFor="let option of field.options">{{option}}</option>
                            </select>

                            <select *ngSwitchCase="'country'" class="form-control" [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required"
                                (change)="changeStates(ngForm.get(field.controlName).value)">
                                <option value="" [innerHTML]="field.label"></option>
                                <option *ngFor="let option of countries">{{option}}</option>
                            </select>

                            <select *ngSwitchCase="'state'" class="form-control" [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required">
                                <option value="" [innerHTML]="field.label"></option>
                                <option *ngFor="let option of states">{{option}}</option>
                            </select>

                            <textarea *ngSwitchCase="'textarea'" class="form-control" [placeholder]="field.label"
                                [formControlName]="field.controlName"
                                [ngClass]="{'is-invalid': ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid}"
                                [required]="field.required"></textarea>
                        </ng-container>
                        <div class="input-group-append clear-field" *ngIf="ngForm.value[field.controlName]"
                            (click)="clearField(field.controlName)"><span class="input-group-text"><i
                                    class="fa fa-times small text-danger"></i></span></div>
                    </div>
                    <div class="invalid-feedback d-block"
                        *ngIf="ngForm.get(field.controlName).touched && ngForm.get(field.controlName).invalid">
                        {{field.label}} is required
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-group mt-3">
            <input type="submit" value="submit" class="btn btn-primary shadow mr-3">
            <input type="reset" value="Reset" class="btn btn-light shadow">
        </div>
    </form>

</div>
