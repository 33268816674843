<footer id="footer-section">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-4 mb-4">
        <a routerLink="">
          <img [src]="'assets/images/logo-yeats-clinic-medicomp-solutions.png'" class="navbar-brand-img mb-4"
            alt="Medicomp Solutions white logo">
        </a>
        <p class="address">
          Medicomp Solutions Inc<br>
          2750 Prosperity Ave.<br>
          Suite #110<br>
          Fairfax, VA 22031
        </p>
        <p class="nums">
          Phone:<a href='tel:703-496-4983'> (703) 496-4983</a><br>
          E-mail:<a href='mailto:info@medicompsolutions.com'>info@medicompsolutions.com</a>
        </p>
        <a routerLink="#" rel="noreferrer">
          <img [src]="'assets/images/Download_on_the_App_Store_Badge_135x40.svg'" alt="Apple app store logo"></a>
      </div>

      <div class="col-6 col-md-3 col-lg-2 mb-3">
        <h5>Solutions</h5>
        <ul class="list-unstyled m-0">
          
          <!-- <li><a routerLink="features">EHR Features</a></li> -->
          <!-- <li><a routerLink="lab-imaging">Lab &amp; Imaging</a></li> -->
          <li><a routerLink="/ehr-emr/large-practice">Large Practice</a></li>
          <li><a routerLink="/ehr-emr/small-practice">Small Practice</a></li>
          <li><a routerLink="ehr-emr/speciality-ehr">Speciality EHR</a></li>
          <li><a routerLink="/university-ehr-emr">Universities &amp; Schools</a></li>
          <li><a routerLink="/hospital-information">For Hospitals</a></li>
        </ul>
      </div>

      <div class="col-6 col-md-3 col-lg-2 mb-3">
        <h5>Practice</h5>
        <ul class="list-unstyled m-0">
          <li><a routerLink="/schedule-demo">Free EHR Demo</a></li>
          <li><a routerLink="/electronic-health-record-ehr">EHR</a></li>
          <li><a routerLink="/practice-management">Practice Management</a></li>
          <li><a routerLink="/medical-billing">Medical Billing</a></li>
          <li><a routerLink="/revenue-cycle-management">Revenue Cycle Management</a></li>
          <li><a routerLink="/patient-portal">Patient Portal</a></li>
          <li><a routerLink="/scheduling">Scheduling</a></li>
          <li><a routerLink="/clinical-support-services">Clinical Support Services</a></li>
          <li><a routerLink="/prescription-management">Prescription Management</a></li>
          <!-- <li><a routerLink="erx-electronic-prescribing">eRx</a></li> -->
          <!-- <li><a routerLink="features-ehr-emr">EHR Checklist</a></li> -->
          <!-- <li><a routerLink="meaningful-use-ehr">Meaningful Use</a></li> -->
          <!-- <li><a routerLink="epcs">EPCS</a></li> -->
          
          <!-- <li><a routerLink="/macra-mips">MACRA &amp; MIPS</a></li> -->
          <!-- <li><a routerLink="colleague-referral">Referral Program</a></li> -->
          <!-- <li><a routerLink="ehr-emr/ICD-10">ICD-10 Info</a></li> -->
          <!-- <li><a routerLink="ehr-user-spotlight">Share your Experience</a></li> -->
          <!-- <li><a routerLink="ehr-emr/security-policy">Security Policy</a></li> -->
        </ul>
      </div>

      <div class="col-6 col-md-3 col-lg-2 mb-3">
        <h5>Resources</h5>
        <ul class="list-unstyled m-0">
          <!-- <li><a routerLink="api">Developer API &amp; SDK</a></li> -->
          <!-- <li><a routerLink="faq-ehr-emr">EHR FAQ</a></li> -->
          <li><a routerLink="/resources/resources">Resources</a></li>
          <li><a routerLink="/medical-form-templates">Forms</a></li>
          <li><a routerLink="/medical-billing-codes/cpt-codes">Billing Codes</a></li>
          <!-- <li><a routerLink="medical-billing-calculator">Medical Billing Calculator</a></li> -->
          <!-- <li><a routerLink="apps">App Directory</a></li> -->
        </ul>
      </div>

      <div class="col-6 col-md-3 col-lg-2 mb-3">
        <h5>About</h5>
        <ul class="list-unstyled m-0">
          <li><a routerLink="/about-us">About Us</a></li>
          <!-- <li><a routerLink="careers">Careers</a></li> -->
          <li><a routerLink="/contact-us">Contact Us</a></li>
          <li><a routerLink="/resources/testimonials">Testimonials</a></li>
          <!-- <li><a routerLink="ehr-emr/logos">Logos &amp; Branding</a></li> -->
        </ul>
      </div>
    </div>
    <div class="row bottom mt-4 mb-md-0 text-center">
      <div class="col-md-3 mb-2 mb-md-0">
        <ul class="list-inline bottom-menu m-0">
          <li class="list-inline-item"><a routerLink="/ehr-emr/privacy-policy">Privacy Policy</a></li>
          <li class="list-inline-item"><a routerLink="/ehr-emr/terms">Terms of Use</a></li>
        </ul>
      </div>
      <div class="col-md-6 copyright mb-2 mb-md-0">
        Copyright © 2020 Medicomp Solutions Inc., All Rights Reserved
      </div>
      <div class="col-md-3 mb-2 mb-md-0">
        <ul class="list-inline social m-0">
          <li class="list-inline-item"><a href="https://twitter.com/YeatsClinical" target="_blank"><img
                [src]="'assets/images/icons/twitter.png'" alt="twitter icon"></a></li>
          <li class="list-inline-item"><a href="https://www.facebook.com/YeatsEHR/" target="_blank"><img
                [src]="'assets/images/icons/facebook.png'" alt="facebook icon"></a></li>
          <li class="list-inline-item"><a href="https://www.youtube.com/user/medicomp-solutions-inc"
              target="_blank"><img [src]="'assets/images/icons/youtube.png'" alt="youtube icon"></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>