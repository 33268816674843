import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // env: string = "local";
  env: string = "dev";
  loading: boolean;
  loadingManual:boolean;

  assetUrl: string = (this.env == "dev") ? "assets" : "/assets";
  apiUrlStatic: string = (this.env == "dev") ? "assets/data" : "assets/data";
  apiUrl: string = (this.env == "dev") ? "api" : "http://localhost/medicomp-solutions/api";
  // apiUrl: string = (this.env == "dev") ? "api" : "http://localhost/christ-king/api";
  // for infy mac system 
  // assetUrl: string = (this.env == "dev") ? "assets" : "http://webelicious.in/demo/medicomp-solutions/assets";
  // apiUrl: string = (this.env == "dev") ? "api" : "http://webelicious.in/demo/medicomp-solutions/assets/data";

  alertMessage: any = [];
  alertMessageLength: number = 0;
  alertshowMore: boolean;
  uniqueId: number = 1;
  
  constructor(private router: Router) { }


  routerLink(url){
    this.router.navigate(url);
  }

  createAlertMessage(data: any) {
    setTimeout(() => {
      this.alertMessageLength = 1;
    });
    var timeStamp = Math.floor(Date.now() / 1000);
    var newMessage = { "id": this.uniqueId, "severity": data.severity, "timeStamp": timeStamp, "message": data.message, 'showMore': data.showMore, "details": data };
    this.alertMessage.unshift(newMessage);
    this.uniqueId++;
  }

}
