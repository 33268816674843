import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  data = {
    "class": "contact-from",
    "title": "Leave us a message",
    "serviceName":"setEnquiry",
    "formFields": [
      { "type": "dropdown", "controlName": "subject", "label": "Select a subject", 
      "options":[
        "General Enquiries", 
        "Support",
        "Sales Inquiry",
        "Billing Issue"
      ]
      , "required": true},
      { "type": "text", "controlName": "name", "label": "Name", "required": true },
      { "type": "textarea", "controlName": "address", "label": "Address", "required": true },
      { "type": "number", "controlName": "zipCode", "label": "ZIP Code", "required": true },
      { "type": "number", "controlName": "phone", "label": "Phone", "required": true },
      { "type": "email", "controlName": "email", "label": "E-Mail", "required": true },
      { "type": "textarea", "controlName": "message", "label": "Message", "required": true }
    ]
  }

  banner = {
    "img": "banners/hero-contact.jpg",
    "title": "Contact Us",
    "subTitle": "We’d love to hear from you!",
    "style":"linear-gradient(to right, rgba(23, 77, 132, 0.91), hsla(134, 100%, 50%, 0.73)), url(assets/images/hero-home-01.jpg",
    "class":"",
    "align":"left",
    "buttons": [
      {
        "title": "Get Started",
        "link": "/sign-up",
        "class": "btn-primary"
      },
      {
        "title": "Get a Quote",
        "link": "ehr-pricing-quote",
        "class": "btn-transparent"
      }
    ]
  }
  constructor() { }

  ngOnInit() {

  }

}
