import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  pageUrl: string;
  banner: any = [];
  pageData: any = [];
  pageData1: any = [];
  authTokenCalled: boolean;
  pageDataDup: any = [];
  destroy = new Subject();
  dataSource: string;
  allFeatures: any = [];
  childUrl: string;
  features: any;
  item: any;
  promotions: any;
  showAllFeaturesFlag: boolean;
  modalData: { };
  timeOutIDs: any = [];
  form: FormGroup;

  constructor(private router: Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {

    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe((params: ParamMap) => {
      this.childUrl = params.get('url');
      console.log(this.childUrl);
      this.item = "helloa";
      this.getPageData(this.childUrl);
    });

    this.form = new FormGroup({
      search: new FormControl('')
    })

  }

  ngOnDestroy() {
    this.destroy.next();  // trigger the unsubscribe
    this.destroy.complete(); // finalize & clean up the subject stream
    this.timeOutIDs.forEach(id => clearTimeout(id));
  }


  getPageData(childUrl) {
    let apiUrl = "features-data.json"
    this.dataService.getPageData(apiUrl).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
      console.log(res);
      this.banner = res.banner;
      this.features = res.features;
      this.promotions = res.promotions;
      this.features.data.forEach(element => {
        this.allFeatures = this.allFeatures.concat(element.items);
      });
      this.features.data[0].items = this.allFeatures;
      let items = this.features.data[0].items
      if (!childUrl || childUrl == '')
        this.item = items[0];
      else {
        let index = items.findIndex(x => x.url == childUrl);
        if (index != -1)
          this.item = items[index];
        else
          this.item = "No data avalable";
      }
    }, errors => {
      console.log(errors);
      this.dataService.getPageData("common-data.json").pipe(takeUntil(this.destroy)).subscribe((res: any) => {
        this.banner = res.banner;
        this.pageData = res.pageData;
      }, errors => {
        console.log(errors);
      });
    });
    $("#largeModal").modal("hide");
  }

  showAllFeatures() {
    this.modalData = { "template": "title-body-media-button", "items": this.features, "class":"featured-modal" };
    this.timeOutIDs.push(
    setTimeout(() => {
      $("#largeModal").modal("show");
    })
    )
  }

}
