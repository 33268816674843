<app-navbar></app-navbar>
<app-banner *ngIf="banner" [banner]="banner"></app-banner>
<section style="padding-top: 10px !important;">
    <div class="container-fluid">
        <div class="text-center title">
            <h1 class="mt-2 text-primary">For Hospitals</h1>
            <hr class="title-divider" style="margin-bottom: 0px !important;">
        </div>
        <div class="row align-items-stretch" style="border: 2px dotted #9e9e9e;">
            <!-- <div class="col-md-1"></div> -->
            <div class="col-md-12">
                <div class="row align-items-stretch"style="border: 2px solid #9e9e9e;">
                    <div class="col-md-12 mt-3">
                        <main class="wrapper">
                            <!-- 1 row -->
                            <div class="eRow">  
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad" class="img-fluid" src="../../../assets/images/updated/mis.jpg" height="500px" width="500px">
                                </div>
                                <div class="eCol align-ri-ver">
                                    <!-- <h2>Text - right + vertical center (align-ri-ver)</h2> -->
                                    <p>Complicated challenges in your healthcare organisation can be overcome by a flexible and efficient For Hospitals or HIS. Today’s healthcare leadership relies heavily on robust and advanced HIS to access information, manage operations and engage assets anywhere at anytime. 
                                        HIS augments the benefits of your EMR by helping you to collect,store, manage, and transmit clinical data securely and in keeping with HIPAA standards.
                                    </p>
                                    <!-- <img src="../../../assets/images/updated/flowchart.png" style="width:100%;height: 200px;"> -->
                                    <div style="padding-top:30px;">
                                        <div class="box">Admission</div>
                                        <div class="box">Placement</div>
                                        <div class="box">Stay</div>
                                        <div class="box">Discharge</div>
                                      </div>
                                      <div class="lines"><div class="line"></div><div class="line"></div><div class="line"></div></div>
                                </div>
                            </div>
                            <hr class="divider">
                            <!-- 2 row -->
                            <div class="eRow">
                                <div class="eCol">
                                    <h2>Operations Management</h2>
                                    <p>HIS evolves with theindustry: Create, Add and Manage services to expand your business.
                                        HIS enables you to coordinate all your business processes and ensure cost-effective, quality care for your patients. Streamline processes to increase efficiency and control errors. Stay up to date on all healthcare reforms and policy changes across your enterprise.
                                    </p>
                                    <div style="margin: 14px -19px;text-align: left;">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li>Improve patient flows</li>
                                                        <li>Access real-time patient data</li>
                                                        <li>Prevent unnecessary expenses due to overuse</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Streamline costing and raise funds</li>
                                                        <li>Time-based or service-based data analysis</li>
                                                    </ul>
                                                </td>                                          
                                            </tr>
                                            </tbody>                                        
                                        </table>
                                     </div>
                                </div>
                        
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad" class="img-fluid" src="../../../assets/images/updated/pms-01.jpg" height="400px" width="500px">
                                </div>
                            </div>
                            <hr class="divider">
                            <p style="color: #174d85;">
                                Redesigning  the business model in healthcare industry by reducing wastage and  establishing standards. 
                            </p>
                            <hr class="divider">
                             <!-- 3 row -->
                             <div class="eRow">
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad" class="img-fluid" src="../../../assets/images/updated/bms.jpg" height="400px" width="500px">
                                </div>
                                <div class="eCol align-ri-ver">
                                    <h2>Bed Management</h2>
                                       <p>HIS helps you configure the number of free and occupied beds in the hospital on a real-time basis. It ensures timely and accurate information flow with regard to vacant occupied beds. You can improve patients experience in the hospital by efficiently utilising your existing bed strength.</p>
                                    <div>
                                        <table>
                                            <tbody>
                                            <tr>                                                
                                                <td>
                                                    <ul>
                                                        <li>Coordinate admissions after assessment </li>
                                                        <li>Place patients into appropriate beds from the admitting department</li>
                                                        <li>Organise and allocate services (radiology, pharmacy) for the patient during the stay in the hospital</li>
                                                     </ul>
                                                </td>                                                                                      
                                            </tr>                                            
                                        </tbody>
                                        
                                        </table>
                                    </div>     
                                </div>
                            </div>
                            <hr class="divider">
                            <!-- 4 row -->
                            <div class="eRow">
                                <div class="eCol">
                                    <h2 style="text-align: left;">Management Information System</h2>
                                    <p>Improves the visibility and transparency across your enterprise. It also ensures that your data is safe from unauthorised use. Connect with stakeholders across geographic locations.  Increase visibility of your business. 
                                    </p>
                                    <p>Unmatched must haves of HIS </p>
                                    <div style="margin: 14px -19px;text-align: left;">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li>Insurance auto-detection </li>
                                                        <li>Insurance templates</li>
                                                        <li>Tax calculations</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Process management</li>
                                                        <li>Legal process records</li>
                                                    </ul>
                                                </td>                                          
                                            </tr>
                                            </tbody>                                        
                                        </table>
                                     </div>
                                 </div>                        
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad" class="img-fluid" src="../../../assets/images/updated/os.jpg" height="400px" width="500px">
                                </div>
                            </div>
                            <hr class="divider">
                            <!-- 5 row -->
                            <div class="eRow">
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                <img alt="" class="img-fluid" src="../../../assets/images/updated/cm.jpg" height="400px" width="500px">
                                </div>
                                <div class="eCol align-ri-ver">
                                <h2>Capacity Management</h2>
                                   <p>HIS helps you to gather a big picture of your infrastructure and helps increase your capacity without compromising quality of services delivered. Measure, monitor and analyse each patient-wise data to create policy changes that effect optimal access to treatment without unnecessary increase in admission duration.</p>
                                <div>
                                    <table>
                                        <tbody>
                                        <tr>                                                
                                            <td>
                                                <ul>
                                                    <li>Easier bed audit and updation on central system</li>
                                                            <li>Efficient staffing policies (nurse/doctor to patient ratio, experience of the staff in each area)</li>
                                                            <li>Prioritise patients according to risk</li>
                                                            <li>Assess risk for outlying patients</li>
                                                 </ul>
                                            </td>                                                                                      
                                        </tr>                                            
                                    </tbody>
                                    
                                    </table>
                                </div>     
                                </div>
                            </div>
                           <hr class="divider">
                           <p style="color: #174d85;">
                            Necessary admissions – appropriate treatment – safe environment – faster and ideal discharge 
                            </p>
                            <hr class="divider">
                           <!-- 6 row -->
                           <div class="eRow">
                               <div class="eCol">
                                   <!-- <h2>Operations management</h2> -->
                                  <p>HIS gives a user-friendly presentable interface. Save time by reducing staff employed for manual entry and paperwork.</p>
                                  <p>Hassle-free and patient-friendly online appointment scheduling reduces waiting times and  manpower requirement. </p>
                                  <p>Modern patient care rests on lifelong-integrated care-records service. Unique digital patient ID creation keeps digital records and clinical history together for easy access. </p>
                                  <p>HIS evolves and synchronises with vendor technologies.</p>
                                  <p>Maintain confidentiality through secure access to medical records. Authorisation controls who can view them in accordance with HIPAA standards.</p>
                                </div>
                               
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad" class="img-fluid" src="../../../assets/images/updated/na.jpg" height="400px" width="500px">
                                </div>
                           </div>
                           <hr class="divider">
                                 <h1>For Hospitals</h1>
                                <table class="container1">
                                  <thead class="thead">
                                    <tr class="tr">
                                      <th class="th"><h1 class="h1">Finance </h1></th>
                                      <th class="th"><h1 class="h1">Administration</h1></th>
                                      <th class="th"><h1 class="h1">Clinical</h1></th>
                                    </tr>
                                  </thead>
                                  <tbody class="tbody">
                                    <tr class="tr">
                                      <td class="td">Costing</td>
                                      <td class="td">Data Security</td>
                                      <td class="td">Patient Records</td>
                                    </tr>
                                    <tr class="tr">
                                      <td class="td">Revenue Management</td>
                                      <td class="td">technologically Equipped</td>
                                      <td class="td">Hospital Inventory</td>
                                    </tr>
                                    <tr class="tr">
                                      <td class="td">Reduce Redundant Manpower</td>
                                      <td class="td">healthcare System</td>
                                      <td class="td">Inpatient</td>
                                    </tr>
                                    <tr class="tr">
                                      <td class="td">Revenue Management</td>
                                      <td class="td">Control Errors and Monitor</td>
                                      <td class="td">Outpatient</td>
                                    </tr>
                                    <tr class="tr">
                                      <td class="td"></td>
                                      <td class="td">Compliance</td>
                                      <td class="td">Nursing</td>
                                    </tr>
                                    <tr class="tr">
                                      <td class="td"></td>
                                      <td class="td">Enhanced Efficiency</td>
                                      <td class="td">Opration Theatre</td>
                                    </tr>
                                    <tr class="tr">
                                        <td class="td"></td>
                                        <td class="td">Quality Indicators</td>
                                        <td class="td">Radiology</td>
                                      </tr>
                                      <tr class="tr">
                                        <td class="td"></td>
                                        <td class="td"></td>
                                        <td class="td">Laboratory</td>
                                      </tr><tr class="tr">
                                        <td class="td"></td>
                                        <td class="td"></td>
                                        <td class="td">Pharma</td>
                                      </tr>
                                  </tbody>
                                </table>
                           <hr class="divider"><br>
                                 <!-- 7 row -->
                            <div class="eRow">  
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="integration" class="img-fluid" src="../../../assets/images/updated/integration.jpg" height="600px" width="500px">
                                </div>
                                <div class="eCol align-ri-ver">
                                    <h2>Device integration </h2>
                                    <p>HIS can provide device integration that helps you keep up with the increased global competition and regulatory requirements.This ensures that critical data can be shared across platforms without losing quality. </p>
                                    <p>HIS accommodates 3rd party systems, medical machinery and laboratory systems and enables communication between these systems.</p>
                                    <p>Device integration opens up communication channels that help you monitor all your devices. Power consumption, equipment downtime and reagent inventory can be tracked to optimise the device usage without compromising quality. </p>
                                    
                                   </div>
                            </div>
                              <!-- 8 row -->
                              <div class="eRow">
                                <div class="eCol">
                                    <!-- <h2>Operations management</h2> -->
                                    <h2>Benefits</h2>
                                    <div style="margin: 14px -19px;text-align: left;">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li>Portability</li>
                                                        <li>Custom reports</li>
                                                        <li>Order management</li>
                                                        <li>Cloud server storage</li>
                                                        <li>Information integrity</li>
                                                        <li>Reduced manpower</li>
                                                        <li>Specialty-specific extensions</li>
                                                        <li>Reduce transcription errors</li>
                                                        <li>Communication both internal and external</li>
                                                      </ul>
                                                </td>
                                                                                        
                                            </tr>
                                            </tbody>                                        
                                        </table>
                                     </div></div>
                        
                                <div class="eCol">
                                    <h2>Characteristics </h2><br>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>                                                
                                                    <td>
                                                        <ul>
                                                            <li>Flexible</li>
                                                            <li>Efficient</li>
                                                            <li>Reliable </li>
                                                            <li>Adaptable</li>
                                                            <li>Cost effective</li>
                                                        </ul>
                                                    </td>                                                                                      
                                                </tr>                                            
                                            </tbody>                                        
                                        </table>
                                    </div> 
                                 </div>
                            </div>
                            <hr class="divider">
                            <div class="eRow">
                                <div class="eCol">
                                    <h2 style="text-align: left;">Laboratory integration module</h2>
                                    <p> Every medical laboratory has high-end machinery that yield enormous data on a daily basis and have tremendous impact on patient care. Manual entry of these data increase chances of error. </p>
                                    
                                        <p>Transcription errors can be eliminated through device integration. Device integration brings all lab machines on a single platform with the HIS and EMR to provide accurate and timely results devoid of transcription errors. All devices are able to communicate with each other and helphealthcare providers with the necessary information to guide patient care.</p>
                                     <p>
                                        Lab integration provides a secure and automated processing of each biological sample that can tracked and interpreted from a laptop or mobile device. Test results accuracy is improved by removing manual errors. Processing times are reduced as several samples can be handled at the same time according to the device capacity. 
                                     </p>
                                 </div>  
                            </div>
                               <hr class="divider">
                               <p style="color: #174d85;">
                                Improving your laboratory’s performance with workflow automation and instrument integration.
                                </p>
                                <hr class="divider">
                                <div class="eRow">
                                    <div class="eCol">
                                        <!-- <h2 style="text-align: left;">Laboratory integration module</h2> -->
                                        <p>Integration is scalable as the number of samples can be increased anytime. Laboratory equipment can be added or removed anytime.All your analysers can be monitoredand tuned easily. You can also keep track of your reagents inventory usageand stock at the same time.</p>
                                        
                                            <p>Faster reporting of critical results occur as data from the machinery is uploaded directly to the EMR after approval. The data can be securely stored and remotely accessed.</p>
                                         <p>
                                            Technicians are freed from cumbersome manual data entry processes and can now divert their efforts to more critical cases. Automated workflow reduces the number reruns, reduces inconsistency and helps processing samples in batches thereby save time.
                                         </p>
                                     </div>  
                                </div>
                                <!-- <h2>Benefits </h2>
                                    <div style="margin: 14px -19px;text-align: left;">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li>Accurate results</li>
                                                        <li>Automated workflow</li>
                                                        <li>Eliminate manual errors</li>
                                                        <li>Reduced turn around time</li>
                                                        <li>Simplified quality management</li>
                                                        <li>Secure data storage and access controls</li>
                                                    </ul>
                                                </td>
                                                                                        
                                            </tr>
                                            </tbody>                                        
                                        </table>
                                     </div>
 -->
                                


                            <div class="eRow">
                                <div class="eCol">
                                    <h2 style="text-align: left;">PACS-EMR integration</h2>
                                    <p> PACS provides access to digital radiology images. Integrating PACS with EMR reduces errors, avoids duplicate testing and expedites patient care. All radiology devices such as ultrasound, X-ray machines, MRI and CT scanners can be interfaced to the HIS. This allows doctors in the OPD or office to access the images on their computers. </p>
                                </div>  
                            </div>

                            <hr class="divider">
                           <!-- ESB -->
                           <div class="eRow" style="margin-top: 10pt;">
                                <div class="eCol">
                                    <h2 style="text-align: left;">ESB - Enterprise Service Bus</h2>
                                    <p>An enterprise service bus or ESB creates incredibly efficient workflows in healthcare. It unifies all your healthcare devices and ensures that your business stays at the fringe of a dynamic productivity front.</p>
                                    <p>We present to you a one stop IT solution that translates your communication and data for all application interfaces. An ESB creates a single, unified communication point for any system in your enterprise and  ensures an efficient workflow by leveraging all other system services to the common platform.</p>
                                    <p>IT solutions in your healthcare establishment must be able to handle the enormous volume of data transfer and operations to ensure that your business runs efficiently. Your technology architecture must allow for maximum flexibility to ensure seamless communications across intranet, internet and cloud services in a simplified, user friendly manner. </p>
                                    
                                </div>                        
                                <div class="text-center d-flex align-items-center p-3 h-100 bg-light" ng-reflect-ng-class="[object Object]">
                                    <img alt="flow" class="img-fluid" src="../../../assets/images/updated/flow.jpg" height="400px" width="570px">
                                </div>
                            </div>
                           <hr class="divider">
                           <p style="color: #174d85;">
                            ESB is the comprehensive architecture of a hassle free healthcare IT platform. 
                            </p>
                            <hr class="divider">
                             <!--9 row -->
                             <div class="eRow">
                                <div class="eCol">
                                    <h2>Characteristics of ESB</h2>
                                    <div style="margin: 14px -19px;text-align: left;">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li>Flexible</li>
                                                        <li>Scalable</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Cost effective</li>
                                                        <li>Service oriented</li>
                                                    </ul>
                                                </td>                                          
                                            </tr>
                                            </tbody>                                        
                                        </table>
                                     </div>
                                     <p><strong>Data security in ESB involves two components: </strong> secure handling by the ESB per se and security assurance by the component systems. All patient data is processed and delivered securely across your enterprise. </p>
                                </div>
                        
                                <div class="eCol">
                                    <p><strong>Interoperability: </strong>Standards, formats and protocols for data transfer vary between systems. ESB platforms provide means to share and process data entities that differ in these aspects.</p>
                                    <p><strong>Data Formats: </strong>ESB recognises several data formats and can transform data from one format to another easily. </p>
                                    <p><strong>Protocols: </strong>Make systems with different technology protocols speak to each other through plug and play transportation components.</p>
                                </div>
                            </div>
                            <hr class="divider">

                             <!-- 10 row -->
                             <div class="eRow">
                                <div class="eCol">
                                    <!-- <h2>Operations management</h2> -->
                                    <h2>Advantages of ESB</h2>
                                    <div style="margin: 14px -19px;text-align: left;">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li>Monitor performance</li>
                                                        <li>Secure data transfer and storage</li>
                                                        <li>Record quality indicators for auditing</li>
                                                        <li>Ensure compliance to HIPAA standards</li>
                                                        <li>Collaborate with your business partners</li>
                                                    </ul>
                                                </td>
                                                                                        
                                            </tr>
                                            </tbody>                                        
                                        </table>
                                     </div>
                                    </div>
                        
                                <div class="eCol">
                                    <h2>Benefits for clinicians</h2><br>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>                                                
                                                    <td>
                                                        <ul>
                                                            <li>Patient data sharing</li>
                                                            <li>Operational efficiency</li>
                                                            <li>Laboratory result integration </li>
                                                            <li>Faster insurance claim settlement </li>
                                                        </ul>
                                                    </td>                                                                                      
                                                </tr>                                            
                                            </tbody>                                        
                                        </table>
                                    </div> 
                                 </div>
                            </div>
                        </main> 
                    </div>                    
                </div>
            </div>
            <!-- <div class="col-md-1"></div> -->
        </div>
    </div>
</section>