<app-navbar></app-navbar>
<app-banner *ngIf="banner" [banner]="banner"></app-banner>
<section>
    <div class="container">
        <div class="text-center title">
            <h1>Contact Us</h1>
            <hr class="title-divider">
        </div>
        <div class="row align-items-stretch">
            <div class="col-md-6 mt-3">
                <div class="mt-2 mb-5">
                    <a routerLink="/">
                        <img [src]="'assets/images/logo-yeats-clinic-medicomp-solutions.png'" class="navbar-brand-img"
                            alt="Medicomp Solutions white logo">
                    </a>
                    <h1 class="mt-2 text-primary">Medicomp Solutions Inc</h1>
                </div>
                <h4>Address</h4>
                <div class="mb-4">
                    Medicomp Solutions Inc<br>
                    2750 Prosperity Ave.<br>
                    Suite #110<br>
                    Fairfax, VA 22031
                </div>
                <h4>General Inquiries</h4>
                <div class="mb-4">
                    Phone:<a href='tel:703-496-4983'> (703) 496-4983</a><br>
                    E-mail:<a href='mailto:info@medicompsolutions.com'>info@medicompsolutions.com</a>
                </div>
                <h4>Sales</h4>
                <div class="mb-4">
                    Phone:<a href='tel:703-204-1460'> (703) 204-1460</a><br>
                    Email:<a href='mailto:sales@medicompsolutions.com'>sales@medicompsolutions.com</a>
                </div>
                <h4>Client Support</h4>
                <div class="mb-4">
                    Toll free Phone:<a href='tel:703-496-4983'> (703) 496-4983</a><br>
                    E-mail:<a href='mailto:support@medicompsolutions.com'>support@medicompsolutions.com</a>
                </div>
                <div class="social_icons">
                    <ul class="list-inline m-0">
                        <li class="list-inline-item">
                            <a class="bg-facebook" routerlink="https://twitter.com/medicomp-solutions-inc"
                                target="_blank" href="/https:/twitter.com/medicomp-solutions-inc">
                                <i class="fa fa-facebook"></i>
                            </a></li>
                        <li class="list-inline-item">
                            <a class="bg-twitter" routerlink="https://www.facebook.com/medicomp-solutions-inc.ehr"
                                target="_blank" href="/https:/www.facebook.com/medicomp-solutions-inc.ehr">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a class="bg-youtube" routerlink="https://www.youtube.com/user/medicomp-solutions-inc"
                                target="_blank" href="/https:/www.youtube.com/user/medicomp-solutions-inc">
                                <i class="fa fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <app-forms *ngIf="data" [data]="data"></app-forms>
            </div>
        </div>

    </div>
</section>
<section class="pt-2">
    <div class="container">
        <!-- <img src="/assets/images/google-map.png" alt="" class="img-fluid w-100"> -->
        <iframe style="width: 100%;height: 400px;border:0;" frameborder="0" allowfullscreen="1"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.095082067718!2d-77.2392634846503!3d38.87606637957401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64b1b1e733a15%3A0x86e77e3a5a3b5dc7!2sMedicomp!5e0!3m2!1sen!2sin!4v1578295077054!5m2!1sen!2sin"></iframe>
    </div>

</section>