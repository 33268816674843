<app-navbar></app-navbar>
<app-banner *ngIf="banner" [banner]="banner"></app-banner>

<ng-container *ngFor="let item of pageData">
    <app-title-body-media-button *ngIf="item.template == 'title-body-media-button'" [items]="item.data"></app-title-body-media-button>
    <app-icon-body-links-columns *ngIf="item.template == 'icon-body-links-columns'" [columnData]="item.data"></app-icon-body-links-columns>
    <app-nav-tabs *ngIf="item.template == 'nav-tabs'" [items]="item.data"></app-nav-tabs>
    <app-accordion *ngIf="item.template == 'accordion'" [data]="item.data"></app-accordion>
    <app-data-table *ngIf="item.template == 'table'" [gridOptions]="item.data"></app-data-table>
    <app-card *ngIf="item.template == 'cards'" [cards]="item.data"></app-card>
    <app-carousels *ngIf="item.template == 'carousels'" [data]="item.data"></app-carousels>
    <app-promotions *ngIf="item.template == 'promotions'" [data]="item.data"></app-promotions>
    <app-users *ngIf="item.template == 'users'" [data]="item.data"></app-users>
    <app-forms *ngIf="item.template == 'forms'" [data]="item.data"></app-forms>
</ng-container>