import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  banner: any = [];
  pageData: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getPageData('home-page-data.json').subscribe((res:any) =>{
      this.banner = res.banner;
      this.pageData = res.pageData;
    });

  }

}
