<section [ngStyle]="{'background-color':!data.crossDesign && data.bgColor ? data.bgColor: ''}">
    <div class="cross-design" *ngIf="data.crossDesign" [ngStyle]="{'background-color':data.bgColor ? data.bgColor: ''}"></div>
    <div class="bg-image-container" *ngIf="data.bgImg"
        [ngStyle]="{'background-image':'url(assets/images/'+ data.bgImg +')'}"></div>
    <div [ngClass]="data.class ? data.class : ''">

        <div class="text-center" *ngIf="data.title && !data.url">
            <h2 [innerHTML]="data.title"></h2>
            <hr class="title-divider">
        </div>
        <div *ngIf="data.title && data.url">
            <div class="d-flex mb-2 justify-content-between align-items-center">
                <h2 [innerHTML]="data.title"></h2>
                <div><a class="btn btn-primary btn-sm" [routerLink]="data.url"> View All</a></div>
            </div>
        </div>
        <div class="w-100 mb-3 px-3" *ngIf="data.dotsList?.length > 0">
            <ul class="d-flex justify-content-center">
                <div class="d-flex shadow-sm">
                    <ng-container *ngFor="let item of data.dotsList;let index = index">
                        <li class="nav-item nav-link py-3 cursor-pointer border"
                            [ngClass]="{'active bg-primary text-light': activePlanId == index}"
                            (click)="goToSlide(index)" [innerHTML]="item">
                            <div></div>
                        </li>
                    </ng-container>

                </div>
            </ul>
        </div>
        <owl-carousel #owlElement class="d-flex mb-2 row align-items-center" [options]="sliderOptions"
            [carouselClasses]="['owl-theme', 'sliding']" [ngClass]="data.subTemplate">

            <ng-container *ngIf="data.subTemplate == 'carousel-images'">
                <ng-container *ngFor="let item of data.items;let i = index">

                <div class="w-100 px-2" *ngIf="item.img && item.img != ''" [title]="item.title" data-toggle="tooltip">
                    <img class="mr-3 img-fluid m-auto" [src]="'assets/images/'+item.img"
                    [alt]="item.title">
                </div>
                <div class="title-logo mx-2 text-truncate-line text-truncate-line-2" [innerHTML]="item.title" [title]="item.title" data-toggle="tooltip" *ngIf="!item.img || item.img == ''"></div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.subTemplate == 'carousel-testimonials'">
                <div class="item w-100" *ngFor="let item of data.items;let i = index">
                    <div class="row media align-items-center">
                        <div class="justify-content-center flex-column h-100 text-center text-md-left border-0"
                            [ngClass]="item.type == 'video' ? 'col-md-4 p-2 p-md-0': 'col-md-2 p-2 p-md-0'">
                            <div class="position-relative" *ngIf="item.type != 'video'">
                                <img class="mr-3 img-fluid w-110 rounded-circle profile-img m-auto"
                                    *ngIf="item.img != ''" [src]="'assets/images/'+item.img" alt="{{item.name}}">
                                <img class="mr-3 img-fluid w-110 rounded-circle profile-img m-auto"
                                    *ngIf="item.img == ''" [src]="'assets/images/default-user.png'"
                                    alt="3:2 {{item.img}}">
                            </div>

                            <div class="card-img position-relative" *ngIf="item.type == 'video'" (click)="setModalPopupData(item.ytVideo)">
                                <div class="youtube-play-btn"><i class="fa fa-youtube-play"></i></div>
                                <img class="img-fluid m-auto zoom" *ngIf="item.ytVideo && item.ytVideo != ''"
                                    [src]="'https://img.youtube.com/vi/'+ item.ytVideo +'/mqdefault-image.jpg'"
                                    alt="{{item.name}}">
                            </div>
                        </div>
                        <div class="media-body pl-3 mt-3 mt-md-0"
                            [ngClass]="item.type == 'video' ? 'col-md-10': 'text-center text-md-left col-md-10'">
                            <h3 class="mt-0">{{item.title}}
                                <span *ngIf="item.profession" class="small">, {{item.profession}}</span>
                            </h3>
                            <div class="plain-text"
                                [ngClass]="{'text-truncate-line text-truncate-line-4': item.type == 'text'}"
                                [innerHTML]="item.body"></div>
                        </div>
                    </div>
                    <button class="float-right btn btn-primary mt-3" *ngIf="item.readMore == 1 && item.type != 'video'"
                        data-toggle="modal" data-target="#largeModal" (click)="getTestimonialDetail(item)">Read
                        more</button>
                </div>
            </ng-container>
            <ng-container *ngIf="data.subTemplate == 'carousel-cards'">
                <div class="h-100 px-4 px-md-3" *ngFor="let item of data.items">
                    <div class="card shadow h-100">
                        <div class="card-img position-relative" *ngIf="item.btnLink">
                            <a [routerLink]="item.btnLink">
                                <div *ngIf="((data.showPlayIcon && item.showPlayIcon != false) || (!data.showPlayIcon && item.showPlayIcon))"
                                    class="youtube-play-btn"><i class="fa fa-youtube-play"></i></div>
                                <img class="card-img-top zoom" *ngIf="item.img" (load)="item.imageLoaded = true"
                                    [src]="'assets/images/'+item.img" alt="{{item.title}}">
                                <img class="card-img-top zoom" *ngIf="!item.img" (load)="item.imageLoaded = true"
                                    [src]="'assets/images/default-image.jpg'" alt="{{item.title}}">
                            </a>
                        </div>

                        <div class="card-img position-relative" *ngIf="item.btnLinkExternal && !item.btnLink">
                            <a [href]="item.btnLinkExternal" target="_blank">
                                <div *ngIf="((data.showPlayIcon && item.showPlayIcon != false) || (!data.showPlayIcon && item.showPlayIcon))"
                                    class="youtube-play-btn"><i class="fa fa-youtube-play"></i></div>
                                <img class="card-img-top zoom" *ngIf="item.img" (load)="item.imageLoaded = true"
                                    [src]="'assets/images/'+item.img" alt="{{item.title}}">
                                <img class="card-img-top zoom" *ngIf="!item.img" (load)="item.imageLoaded = true"
                                    [src]="'assets/images/default-image.jpg'" alt="{{item.title}}">
                                <div class="img-loading d-flex bg-secondary" *ngIf="!item.imageLoaded">
                                    <div><i class="fa fa-image"></i></div>
                                    <div class="small">Loading...</div>
                                </div>
                            </a>
                        </div>

                        <div class="card-body">
                            <a *ngIf="item.btnLink" [routerLink]="item.btnLink">
                                <h4 class="card-title" [ngClass]="item.subTitle ? 'mb-2': 'mt-2 mb-4'"
                                    [title]="item.title" [innerHTML]="item.title"></h4>
                            </a>
                            <a *ngIf="item.btnLinkExternal" [routerLink]="item.btnLinkExternal">
                                <h4 class="card-title" [ngClass]="item.subTitle ? 'mb-2': 'mt-2 mb-4'"
                                    [title]="item.title" [innerHTML]="item.title"></h4>
                            </a>
                            <h6 *ngIf="item.subTitle" class="small text-secondary mb-3 text-truncate"
                                [innerHTML]="item.subTitle">
                            </h6>
                            <div class="card-text plain-text" *ngIf="item.body" [innerHTML]="item.body | safeHtml">
                            </div>
                            <div class="mt-4 mb-3 text-center"
                                *ngIf="item.btnTitle && (item.btnLink || item.btnLinkExternal)">
                                <a [routerLink]="item.btnLink" *ngIf="item.btnLink"><button
                                        class="btn btn-primary">{{item.btnTitle}}</button></a>
                                <a [href]="item.btnLink" target="_blank" *ngIf="item.btnLinkExternal"><button
                                        class="btn btn-primary">{{item.btnTitle}}</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data.subTemplate == 'carousel-table-single-column'">
                <div class="item w-100" *ngFor="let item of data.items" class="w-100 text-center shadow">
                    <div class="p-4 border-top">
                        <h2 [innerHTML]="item.title"></h2>
                        <a [routerLink]="item.btnLink" [innerHTML]="item.btnText" class="btn btn-primary mt-3"></a>
                    </div>

                    <ul class="list-unstyled border-bottom m-0">
                        <ng-container *ngFor="let list of item.lists">
                            <li *ngIf="list.link" class="px-2 py-3 border border-bottom-0">
                                <span [innerHTML]="list.text"></span></li>
                            <li *ngIf="!list.link" [innerHTML]="list.text"
                                class="px-2 py-3 border border-bottom-0 bg-secondary text-light"></li>
                        </ng-container>
                    </ul>
                </div>
            </ng-container>

        </owl-carousel>

    </div>
</section>

<app-modal *ngIf="modalData && modalData != ''" [data]="modalData"></app-modal>
