import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorsService {

  constructor(private sharedService: SharedService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    request.clone({
        headers: request.headers.set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
    });

    this.sharedService.loading = true;
    return next.handle(request).pipe(
        tap(
            event => {
                if (event instanceof HttpResponse) {
                    this.sharedService.loading = false;
                }
            },
            error => {
                if (error instanceof HttpErrorResponse) {
                    this.sharedService.loading = false;
                }
            }
        )
    );
}
}
