import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';
// import 'rxjs/observable/of';

import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  authToken: string;
  countryStateAndCities: any = [];
  countries: any = [];
  countryCodes: any = [];

  constructor(private http: HttpClient, public sharedData: SharedService) { }

  // httpHeaders: any = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };

  httpHeaders = new HttpHeaders().set("authorization", "bearer ")
  httpHeadersLocal: any = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'Server Error');
  }

  getPageData(pageUrl: string) {
    return this.http.get(this.sharedData.apiUrlStatic + "/" + pageUrl)
      .pipe(catchError(this.errorHandler));
  }

  getAuthToken() {
    return this.http.post("https://productionyeatsehrtest-api.azurewebsites.net/api/v1/TokenAuthentication", { 'email': 'test_admin', 'password': 'admin' })
  }

  getTemplateGroupData() {
    return this.http.get("https://productionyeatsehrtest-api.azurewebsites.net/api/v1/TemplateGroup/GetActiveTemplateGroup", { headers: this.httpHeaders })
  }

  getTemplateGroupDataByCode() {
    return this.http.get("https://productionyeatsehrtest-api.azurewebsites.net/api/v1/Forms", { headers: this.httpHeaders })
  }

  getCptCodes() {
    return this.http.get("https://productionyeatsehrtest-api.azurewebsites.net/api/v1/CptCode/SearchCPTCodes?pDescription=test", { headers: this.httpHeaders })
  }
  SearchCptCodes(paramsData) {
    let params = paramsData
    return this.http.get("https://productionyeatsehrtest-api.azurewebsites.net/api/v1/CptCode/SearchCPTCodes?" + params, { headers: this.httpHeaders })
  }

  getIcd10Codes() {
    return this.http.get("https://productionyeatsehrtest-api.azurewebsites.net/api/v1/Users/GetPhysician_common_icd10_code", { headers: this.httpHeaders })
  }

  setEnquiry(formData: any) {
    return this.http.post(this.sharedData.apiUrl + "/setEnquiry.php", formData, { headers : this.httpHeadersLocal})
      .pipe(catchError(this.errorHandler));
  }

  setRegistration(formData: any) {
    return this.http.post(this.sharedData.apiUrl + "/setRegistration.php", formData, { headers : this.httpHeadersLocal})
      .pipe(catchError(this.errorHandler));
  }

  getCountryStateAndCities() {
    return this.http.get(this.sharedData.apiUrlStatic + "/countries-states-cities.json")
      .pipe(catchError(this.errorHandler));
  }

}
