import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  
  @Input() cards: any = [];
  @Input() config: any = {};
  
  constructor() { }
  
  ngOnInit() {
    // this.config = this.cards && this.cards.config && Object.keys(this.cards.config).length > 0 ? this.cards.config : {"layout":"card-img-title-body", "columns":3};
  }


}
