import { Component, OnInit } from '@angular/core';
import { SharedService }from '../../services/shared.service';

@Component({
  selector: 'app-loader',
  template: `
  <div id="preloader" *ngIf="sharedData.loading || sharedData.loadingManual">
  <div class="preloader">
    <div class="ball-pulse">
      <div>
      </div>
      <div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(public sharedData:SharedService) { }

  ngOnInit() {
  }

}
