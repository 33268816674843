/**
 * The default template and styles for the pagination links are borrowed directly
 * from Zurb Foundation 6: http://foundation.zurb.com/sites/docs/pagination.html
 */

export const DEFAULT_TEMPLATE = `
    <pagination-template  #p="paginationApi"
                         [id]="id"
                         [maxSize]="maxSize"
                         (pageChange)="pageChange.emit($event)">
    <ul class="ngx-pagination" 
        role="navigation" 
        [attr.aria-label]="screenReaderPaginationLabel" 
        [class.responsive]="responsive"
        *ngIf="!(autoHide && p.pages.length <= 1)">

        <li class="pagination-previous" [class.disabled]="p.isFirstPage()" *ngIf="directionLinks"> 
            <a tabindex="0" *ngIf="1 < p.getCurrent()" (keyup.enter)="p.previous()" (click)="p.previous()" [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel">
                {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span *ngIf="p.isFirstPage()">
                {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li> 

        <li class="small-screen">
            {{ p.getCurrent() }} / {{ p.getLastPage() }}
        </li>

        <li [class.current]="p.getCurrent() === page.value" 
            [class.ellipsis]="page.label === '...'"
            *ngFor="let page of p.pages">
            <a tabindex="0" (keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span class="show-for-sr">{{ screenReaderPageLabel }} </span>
                <span>{{ page.label }}</span>
            </a>
            <ng-container *ngIf="p.getCurrent() === page.value">
                <span class="show-for-sr">{{ screenReaderCurrentLabel }} </span>
                <span>{{ page.label }}</span> 
            </ng-container>
        </li>

        <li class="pagination-next" [class.disabled]="p.isLastPage()" *ngIf="directionLinks">
            <a tabindex="0" *ngIf="!p.isLastPage()" (keyup.enter)="p.next()" (click)="p.next()" [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel">
                 {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span *ngIf="p.isLastPage()">
                 {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li>

    </ul>
    </pagination-template>
    `;

export const DEFAULT_STYLES = `
.ngx-pagination {
  margin: 1rem 0;
  padding:0;
  text-align:center;
}

.ngx-pagination::before,
.ngx-pagination::after {
  content: ' ';
  display: table;
}

.ngx-pagination::after {
  clear: both;
}

.ngx-pagination li {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  // margin-right: 0.0625rem;
  border-radius: 0;
}

.ngx-pagination li {
  display: inline-block;
}

.ngx-pagination a,
.ngx-pagination button {
  color: #0a0a0a;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1a6293;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.ngx-pagination li:first-child, .ngx-pagination li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.ngx-pagination li:last-child, .ngx-pagination li:last-child a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: #e6e6e6;
  text-decoration: none;
}
.ngx-pagination a:focus{
  position:relative;
}

.ngx-pagination .current {
  padding: 0.5rem 0.75rem;
  background: #1a6293;
  color: #fefefe;
  cursor: default;
  border-top: 1px solid #1a6293;
  border-bottom: 1px solid #1a6293;
}

.ngx-pagination .disabled {
  padding: 0.5rem 0.75rem;
  color: #cacaca;
  cursor: default;
  border: 1px solid #dee2e6;
}

.ngx-pagination .disabled:hover {
  background: transparent;
}

.ngx-pagination a,
.ngx-pagination button {
  cursor: pointer;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: '\u00AB';
  display: inline-block;
  // margin-right: 0.5rem;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: '\u00BB';
  display: inline-block;
  // margin-left: 0.5rem;
}

.ngx-pagination .show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.ngx-pagination .small-screen {
  display: none;
}

@media screen and (max-width: 601px) {
  .ngx-pagination.responsive .small-screen {
      display: inline-block;
  }

  .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
      display: none;
  }

}
  `;
