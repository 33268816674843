import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'completeSearch'
})
export class CompleteSearchPipe implements PipeTransform {

  public transform(value: any, term: string): any {

    if (!term) return value;
    return (value || []).filter((item) => {

      for (var key in item) {
        if(item.hasOwnProperty(key) && typeof item[key] !== 'object' && typeof item[key] !== 'boolean' && item[key].toString().toLowerCase().indexOf(term.toLowerCase()) != -1){
          return item;
        }
      }
    });

  }
}

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args.length == 0)
      return value;

    return value.filter(item => {
      var matchesAll = true;

      // for(var i = 0; i<args.length; i++){

      if (item.hasOwnProperty(Object.keys(args[0]).toString()) && (item[Object.keys(args[0]).toString()]).toString().toLowerCase().indexOf(Object.values(args[0]).toString().toLowerCase()) != -1) {
        return item;

      } else { // at least one column did not match,
        matchesAll = false;
      }
      // }
      return matchesAll;
    });
  }
}