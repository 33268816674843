import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  @Input() data: any;
  constructor(private dataService: DataService, private sharedService: SharedService) { }

  ngForm = new FormGroup({});
  countryCodes: any = [];
  countryStateAndCitiesDataLength: number;
  countryStateAndCities: any;
  countries: any = [];
  countryCodesDataLength: number;
  country: any;
  profileImage: File = null;
  states: any;
  districts: any;

  ngOnInit() {
    this.getCountryStateAndCities();
  }

  ngAfterContentInit() {
    this.data.formFields.forEach(element => {
      this.ngForm.addControl(element.controlName, new FormControl(''));
    });
  }

  clearField(field) {
    this.ngForm.controls[field].setValue('');
  }
  onSubmit() {
    this.ngForm.markAllAsTouched();
    if (this.ngForm.valid) {
      let formValue = this.ngForm.value;
      // console.log(formValue);


      this.dataService[this.data.serviceName](formValue).subscribe((res: any) => {
        if (res.severity == "success") {
          // alert("Message sent successfully");
          this.sharedService.createAlertMessage(res);
          this.ngForm.reset();
          this.data.formFields.forEach((element:any = {}) => {
            element.type == "dropdown"
            this.ngForm.controls[element.controlName].setValue('');
          });
        } else {
          // alert("Something went wrong!");
          this.sharedService.createAlertMessage(res);
        }
      }, errors => {
        let alert = [];
        alert['severity'] = 'danger';
        alert['message'] = "Something went wrong!";
        this.sharedService.createAlertMessage(alert);
      });
    } else {
      let alert = [];
      alert['severity'] = 'danger';
      alert['message'] = "Please fill all required fields";
      this.sharedService.createAlertMessage(alert);
    }
  }

  getCountryStateAndCities() {
    this.countryStateAndCitiesDataLength = this.dataService.countryStateAndCities ? this.dataService.countryStateAndCities.length : 0;

    if (this.countryStateAndCitiesDataLength == 0) {
      this.dataService.getCountryStateAndCities().subscribe((res: any) => {
        this.countryStateAndCities = res;
        this.countryStateAndCities.forEach(element => {
          this.countries.push(element.name);
          this.countryCodes.push({ 'code': element.iso2, 'dial_code': element.phone_code });
        });
        this.dataService.countryStateAndCities = this.countryStateAndCities;
        this.dataService.countries = this.countries;
        this.dataService.countryCodes = this.countryCodes;

      }, errors => {
        console.log(errors);
      });
    } else {
      this.countryStateAndCities = this.dataService.countryStateAndCities;
      this.countries = this.dataService.countries;
      this.countryCodes = this.dataService.countryCodes;
    }
  }

  changeStates(country) {
    let index = this.countryStateAndCities.findIndex(x => x.name == country);
    this.states = Object.keys(this.countryStateAndCities[index].states);
    this.ngForm.controls['state'].setValue('');
  }

  changeDistricts(country: string, state: string) {
    let countryIndex = this.countryStateAndCities.findIndex(x => x.name == country);
    this.districts = this.countryStateAndCities[countryIndex].states[state];
    // this.districts =  this.stateAndCity.states[index].districts;
    // this.ngForm.controls['state'].setValue('');
  }

}


