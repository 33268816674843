<div class="jumbotron position-relative {{banner.class}}">
    <div class="bg-image-container" *ngIf="banner.img"
        [ngStyle]="{'background-image':'url(assets/images/'+ banner?.img + ')'}">
    </div>
    <div class="overlay"></div>
    <div class="container position-relative">
        <div class="banner-content" [ngClass]="banner.align == 'left' ? 'text-left' : banner.align == 'right' ? 'text-right ml-auto' : 'text-center m-auto'">
            <h1 class="text-light" *ngIf="banner.title" [innerHTML]="banner.title"></h1>
            <p *ngIf="banner.subTitle" [innerHTML]="banner.subTitle"></p>
            <p class="btn-wrapper">
                <a class="btn m-2 btn-lg shadow {{btn.class}}" [routerLink]="btn.link" [innerHTML]="btn.title"
                    *ngFor="let btn of banner.buttons"></a>
            </p>
        </div>
    </div>
    <svg class="al-wave" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1240 80"
        style="enable-background:new 0 0 1240 120;" xml:space="preserve">
        <path class="hk" d="M682.2,39.6C418.5-11.1,140.2,9.9,0,30v96h1440V30C1252.7,52.2,1011,96.4,675.6,38.7z"
            fill="#ffffff"></path>
    </svg>
</div>