import { Component, OnInit, Input, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() type: string;
  @Input() currentParentMenu: string;
  @Input() currentMenu: string;

  currentTab: string;
  currentParentTab: string = "";
  pageUrl: string;
  routeUrl: string;
  testUrlFromApp: any;
  showMe: boolean;

  navbarData: any = [
    {
      'name': 'Solutions', 'url': '/solutions', 'subMenus': [
        { 'name': 'Large Practice', 'url': '/ehr-emr/large-practice' },
        { 'name': 'Small Practice', 'url': '/ehr-emr/small-practice' },
        { 'name': 'Speciality EHR', 'url': '/ehr-emr/speciality-ehr' },
        { 'name': 'Universities and Schools', 'url': '/partners/university-ehr-emr' },
        { 'name': 'Hospital Information System', 'url': '/hospital-information' },
      ]
    },
    {
      'name': 'Products', 'url': '/Products', 'subMenus': [
        { 'name': 'Electronic Health Records', 'url': '/electronic-health-record-ehr' },
        { 'name': 'Practice Management', 'url': '/practice-management/' },
        { 'name': 'Medical Billing', 'url': '/medical-billing' },
        { 'name': 'Revenue Cycle Management', 'url': '/revenue-cycle-management' },
        { 'name': 'Patient Portal', 'url': '/patient-portal' },
        { 'name': 'Patient Inventory Management', 'url': '/patient-inventory-management' },
        { 'name': 'Mobile EHR App', 'url': '/mobile-ipad-ehr-app' },
        { 'name': 'Scheduling', 'url': '/scheduling' },
        { 'name': 'Clinical Support Services', 'url': '/clinical-support-services' },
        { 'name': 'Prescription Management', 'url': '/prescription-management' },
        { 'name': 'Highlights of Yeats clinical', 'url': '/highlights-of-yeats-clinical' },
        // { 'name': 'Highlights of Yeats clinical', 'url': '/hospital-information' },
        // { 'name': 'Radiology Information System', 'url': '/radiology-information-system' },
        // { 'name': 'Laboratory Information System', 'url': '/laboratory-information-system' },
        // { 'name': 'Device Integration', 'url': '/device-integration' },
        // { 'name': 'HL7 Integration Solution', 'url': '/hl7-integration-solution' },

      ]
    },
    { 'name': "Pricing", 'url': '/plans-and-pricing' },
    {
      'name': 'Resources', 'url': '/resources', 'subMenus': [
        { 'name': 'MACRA', 'url': '/macra-mips' },
        { 'name': 'ONC Certification', 'url': '/onc-certification' },
        // {
        //   'name': 'Value Based Care', 'url': '/', 'subMenus': [
        //     { 'name': 'MACRA', 'url': '/macra-mips' },
        //     { 'name': 'ONC Certification', 'url': '/meaningful-use-ehr' },
        //   ]

        // },
        // {
        //   'name': 'Resources', 'url': '/resources', 'subMenus': [
        //     { 'name': 'All Resources', 'url': '/resources' },
        //     { 'name': 'Feature Videos', 'url': '/ehr-feature-videos' },
        //     { 'name': 'Training Videos', 'url': '/ehr-training-videos' },
        //     { 'name': 'Specialties', 'url': '/specialties' },
        //     { 'name': 'Case Studies', 'url': '/case-studies' },
        //     { 'name': 'Additional Resources', 'url': '/additional-resources' },
        //     { 'name': 'Testimonials', 'url': '/testimonials' },
        //   ]
        // },
        // { 'name': 'Feature Videos', 'url': '/resources/ehr-feature-videos' },
        { 'name': 'Training Videos', 'url': '/resources/ehr-training-videos' },
        { 'name': 'Specialities', 'url': '/resources/specialities' },
        // { 'name': 'Case Studies', 'url': '/resources/case-studies' },
        { 'name': 'Additional Resources', 'url': '/resources/additional-resources' },
        // { 'name': 'Testimonials', 'url': '/resources/testimonials' },
        // { 'name': 'Refer a Practice', 'url': '/ehr-emr/partners/affiliates' },
        { 'name': 'Forms', 'url': '/medical-form-templates' },
        {
          'name': 'Billing Codes', 'url': '/medical-billing-codes', 'subMenus': [
            { 'name': 'CPT codes', 'url': '/cpt-codes' },
            // { 'name': 'ICD10 Codes', 'url': '/icd10-codes' },
          ]
        },
        // { 'name': 'Plans', 'url': '/plans-and-pricing' },
        // { 'name': 'Support', 'url': '/support' },
        // { 'name': 'Refer a Colleague', 'url': '/colleague-referral' },
      ]
    },
    { 'name': "About", 'url': '/about-us' },
    { 'name': "Contact", 'url': '/contact-us' }
  ]

  constructor(@Inject(WINDOW) private window: Window, ) { }

  ngOnInit() {
    this.currentTab = 'home';

    // setTimeout(() => {
    //   $('.navbar-nav li a.hide-click, .navbar-brand, .breadcrumb-item a').on('click', function () {
    //     $('.navbar-collapse').collapse('hide');
    //   });
    // }, 0);

    this.currentTab = '/' + this.currentMenu;
    let firstIndex = this.navbarData.findIndex(x => x.url == this.currentTab);
    if (firstIndex == -1) {
      this.navbarData.forEach(element => {
        let loopContinue = true;
        if (loopContinue) {
          let index = element.subMenus ? element.subMenus.findIndex(x => x.url == this.currentTab) : -1;
          if (index != -1) {
            this.currentParentTab = element.url;
            loopContinue = false;
          }
        }
      });
    } else if (firstIndex != -1) {
      this.currentParentTab = this.navbarData[firstIndex].url;
    }

  }

  currentActiveTab(tab, parentTab) {
    this.currentTab = tab;
    this.currentParentTab = parentTab;
  }

  ngAfterViewInit() {
    // console.log("hi");

    var zero = 0;
    $(this.window).on("scroll", () => {
      if ($(this.window).scrollTop() > 100) {
        $(".navbar-main").toggleClass("navbar-hidden", $(this.window).scrollTop() > zero);
        zero = $(this.window).scrollTop();
      } else {
        $(".navbar-main").removeClass("navbar-hidden", $(this.window).scrollTop() > zero);
        zero = $(this.window).scrollTop();
      }

      $(".navbar-main").toggleClass("navbar-dark bg-primary", $(this.window).scrollTop() > 100);
    });


    $(".navbar-toggler").on("click", () => {
      if ($(this.window).scrollTop() < 100) {
        if (!$("#navbarSupportedContent").hasClass("show"))
          $(".navbar-main").addClass("navbar-dark bg-primary");
        else
          $(".navbar-main").removeClass("navbar-dark bg-primary");
      }
    });


    // Multi Level dropdowns
    $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      $(this).siblings().toggleClass("show");
      // if (!$(this).next().hasClass('show')) {
      //   $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
      // }
      // $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      //   $('.dropdown-submenu .show').removeClass("show");
      // });
    });
  }

}
