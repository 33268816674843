import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { OwlModule } from 'ngx-owl-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerComponent } from './components/banner/banner.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CardComponent } from './components/card/card.component';
import { SafeHtmlPipe, SafeUrlPipe, DataFilterPipe, CompleteSearchPipe  } from "./pipes/filters.pipe";
import { CarouselsComponent } from './components/carousels/carousels.component';
import { PostsComponent } from './components/posts/posts.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { FormsComponent } from './components/forms/forms.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TitleBodyMediaButtonComponent } from './components/title-body-media-button/title-body-media-button.component';
import { IconBodyLinksColumnsComponent } from './components/icon-body-links-columns/icon-body-links-columns.component';
import { InterceptorsService } from './services/interceptors.service';
import { LoaderComponent } from "./components/loader/loader.component";
import { DataTableComponent } from './components/data-table/data-table.component';
import { NgxPaginationModule } from './components/ngx-pagination/ngx-pagination.module';
import { UsersComponent } from './components/users/users.component';
import { FeaturesComponent } from './components/features/features.component';
import { ContactComponent } from './components/contact/contact.component';
import { ModalComponent } from './components/modal/modal.component';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { HospitalInformationComponent } from './components/hospital-information/hospital-information.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    BannerComponent,
    NavTabsComponent,
    AccordionComponent,
    CardComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    CarouselsComponent,
    PostsComponent,
    PromotionsComponent,
    FormsComponent,
    PageNotFoundComponent,
    TitleBodyMediaButtonComponent,
    IconBodyLinksColumnsComponent,
    LoaderComponent,
    DataTableComponent,
    CompleteSearchPipe,
    DataFilterPipe,
    UsersComponent,
    FeaturesComponent,
    ContactComponent,
    ModalComponent,
    AlertMessageComponent,
    HospitalInformationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    OwlModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    BrowserAnimationsModule
  ],
  providers: [
    DataFilterPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
