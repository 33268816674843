<section [ngStyle]="{'background-color':items.bgColor ? items.bgColor: ''}">
    <div class="mt-4 mx-auto container {{items.class}}">
        <div class='text-center' *ngIf="items.title">
            <h2 [innerHTML]="items.title"></h2>
            <hr class='title-divider'>
        </div>
        <div class="row">
            <ul class="nav nav-tabs text-center w-100 d-inline-block" role="tablist">
                <li role="presentation" class="text-center" *ngFor="let item of items.tabs; let first = first">
                    <a href="#{{item.id}}" [attr.aria-controls]="item.id" role="tab" data-toggle="tab"
                        class="semi-bold d-block" [ngClass]="{'active': first}" aria-expanded="true">
                        <div *ngIf="item.icon" class="icon-wrapper mb-2"><i class="fa fa-{{item.icon}}"></i></div>
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div role="tabpanel" class="tab-pane fade" *ngFor="let item of items.tabPanels;let first = first" [id]="item.id"
                [ngClass]="{'in active show': first}" [innerHTML]="item.content">
            </div>
        </div>
    </div>
    
</section>