<section [ngStyle]="{'background-color':columnData.bgColor && !columnData.crossDesign ? columnData.bgColor: ''}" class="{{columnData.sectionClass}}">
    <div class="cross-design" *ngIf="columnData.crossDesign" [ngStyle]="{'background-color':columnData.bgColor ? columnData.bgColor: ''}"></div>
    <div class="bg-image-container" *ngIf="columnData.bgImg"
        [ngStyle]="{'background-image':'url(assets/images/'+ columnData.bgImg +')'}"></div>
    <div [ngClass]="columnData.class ? columnData.class : ''">
        <div *ngIf="columnData.title" class="text-center position-relative">
            <h2 [innerHTML]="columnData.title"></h2>
            <hr class="title-divider">
        </div>
        <div class="row text-center align-items-stretch">
            <div class="my-4"
                [ngClass]="columnData.columns == 4 ? 'col-md-3' : columnData.columns == 3 ? 'col-md-4' : 'col-md-6'"
                *ngFor="let item of columnData.items">
                <a *ngIf="item.url && item.url != ''" [routerLink]="item.url">
                    <div class="card h-100 shadow">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <img *ngIf="item.img && !item.icon" [src]="'assets/images/icons/'+item.img"
                                    alt="patient first practice management software">
                                    <div *ngIf="item.icon">
                                        <i class="fa fa-{{item.icon}} fa-4x duplicate" *ngIf="(columnData.class).includes('rotate-in')"></i>
                                        <i class="fa fa-{{item.icon}} fa-4x"></i>
                                    </div>

                                </div>
                            <h4 class="my-3"[innerHTML]="item.title"></h4>
                            <p class="text-center" [innerHTML]="item.body"></p>
                            <div class="text-center" *ngFor="let link of item.links">
                                <a class="d-block" [routerLink]="link.url" [innerHTML]="link.text"><i
                                        class="fa fa-angle-right ml-1"></i></a>
                            </div>
                        </div>
                    </div>
                </a>
                <a *ngIf="!item.url || item.url == ''">
                    <div class="card h-100 shadow">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <img *ngIf="item.img && !item.icon" [src]="'assets/images/icons/'+item.img"
                                    alt="patient first practice management software">
                                    <div *ngIf="item.icon">
                                        <i class="fa fa-{{item.icon}} fa-4x duplicate" *ngIf="(columnData.class).includes('rotate-in')"></i>
                                        <i class="fa fa-{{item.icon}} fa-4x"></i>
                                    </div>

                                </div>
                            <h4 class="my-3"[innerHTML]="item.title"></h4>
                            <p class="text-center" [innerHTML]="item.body"></p>
                            <div class="text-center" *ngFor="let link of item.links">
                                <a class="d-block" [routerLink]="link.url" [innerHTML]="link.text"><i
                                        class="fa fa-angle-right ml-1"></i></a>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>