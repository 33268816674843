import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { OwlCarousel } from 'ngx-owl-carousel';
declare var $: any;

@Component({
  selector: 'app-carousels',
  templateUrl: './carousels.component.html',
  styleUrls: ['./carousels.component.scss']
})
export class CarouselsComponent implements OnInit {
  activePlanId: number;
  timeOutIDs:any = [];
  modalData:any;
  constructor() { }
  @Input() data: any = [];
  @ViewChild('owlElement') owlElement: OwlCarousel;

  sliderOptions = {
    responsive: {'320': { items: 1 },'480': { items: 1 }, '768': { items: 1 }, "992": { items: 1 } }, items: 1, dots: true, nav: false, navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"], loop: true, autoplay: true, autoplaySpeed: 500, autoplayTimeout: 25000, autoplayHoverPause: true,

    onChanged: (e) => {
      this.onChanged(e);
    }, data: ['sfdasf', 'sffsaf']
  };

  ngOnInit() {
    this.sliderOptions.responsive[992].items = this.data.columns ? this.data.columns : this.sliderOptions.responsive[992].items;
    this.sliderOptions.dots = this.data.dotsList && this.data.dotsList.length > 0 ? false : true;

    if (this.data.subTemplate == 'carousel-cards') {
      this.sliderOptions.responsive[992].items = this.data.columns;
      this.sliderOptions.nav = true;
      this.sliderOptions.autoplayTimeout = 10000;
    } else
    if (this.data.subTemplate == 'carousel-table-single-column') {
        this.sliderOptions.responsive = {'320': { items: 1 }, '480': { items: 1 }, '768': { items: 1 }, "992": { items: 1 } };
        this.sliderOptions.nav = true;
    }else
    if (this.data.subTemplate == 'carousel-images') {
      this.sliderOptions.responsive = {'320': { items: 2 },'480': { items: 2 }, '768': { items: 2 }, "992": { items: 5 } };
    }
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });

  }

  goToSlide(slide) {
    this.owlElement.to([slide]);
  }

  ngOnDestroy(){
    this.timeOutIDs.forEach(id => clearTimeout(id));
  }


  onChanged(e) {
    if (this.data.subTemplate == 'carousel-table-single-column' && this.data.dotsList.length > 0) {
      this.timeOutIDs.push(
      setTimeout(() => {
        let heading = $(".owl-stage .active h2").length > 0 ? $(".owl-stage .active h2").text() : this.data.dotsList[0];
        // console.log("onChanged " + heading);
        let index = this.data.dotsList.indexOf(heading);
        this.activePlanId = index;
      }, 10)
      )
    }
  }

  getTestimonialDetail(item){
    
  }

  setModalPopupData(videoId){
    this.modalData = {'template':'ytVideo', 'videoId': videoId};
    $("#largeModal").modal("show");
  }

}
