import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { HttpHeaders } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  pageUrl: string;
  banner: any = [];
  pageData: any = [];
  authTokenCalled: boolean;
  pageDataDup: any = [];
  destroy = new Subject();
  dataSource: string;
  detailsPage: boolean;
  dataUrl: string;

  constructor(private router: Router, private route: ActivatedRoute, private dataService: DataService, private sharedService: SharedService) { }

  ngOnInit() {

    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe((param: ParamMap) => {
      this.route.data.subscribe((val) => {
        this.dataUrl = val.jsonFile;
        this.detailsPage = val.detailsPage;
        this.dataSource = val.dataSource;
        this.pageUrl = (this.router.url).split("/")[(this.router.url).split("/").length - 1].split("?")[0];
        this.getPageData();
      });

    });
  }

  ngOnDestroy() {
    this.destroy.next();  // trigger the unsubscribe
    this.destroy.complete(); // finalize & clean up the subject stream
  }

  getAuthToken() {
    if (!this.authTokenCalled && !this.dataService.authToken) {
      this.dataService.getAuthToken().pipe(takeUntil(this.destroy)).subscribe((res: any) => {
        // console.log("token");
        // console.log(res.access_token);
        this.authTokenCalled = true;
        this.dataService.authToken = res.access_token;
        this.dataService.httpHeaders = new HttpHeaders().set("authorization", "bearer " + res.access_token);
        this.getAzureApiData();
      });
    } else {
      this.getAzureApiData();
    }
  }

  getAzureApiData() {
    let apiCall: string;
    let params: string;
    if (this.pageUrl == 'medical-form-templates') {
      apiCall = "getTemplateGroupData";
      params = "pPhysicianId=''" + "pCategoryId=''" + "pCode=''" + "pDescription='test'";
    } else
      if (this.pageUrl == 'cpt-codes') {
        apiCall = "getCptCodes";
        params = '';
      } else
        if (this.pageUrl == 'icd10-codes') {
          apiCall = "getIcd10Codes";
          params = '';
        }

    this.pageDataDup = JSON.parse(JSON.stringify(this.pageData));
    this.pageData = [];
    this.dataService[apiCall](params).pipe(takeUntil(this.destroy)).subscribe(res => {
      // console.log(res);
      let index = this.pageDataDup.findIndex(x => x.template == 'table');
      if (index != -1 && res) {
        this.pageDataDup[index].data.data = res;
        this.pageData = JSON.parse(JSON.stringify(this.pageDataDup));
      }
    });
  }

  getPageData() {
    let apiUrl;
    let pageDataLocal;
    let bannerLocal;

    if (this.dataUrl) {
      apiUrl = this.dataUrl + "-data.json";
      pageDataLocal = this.dataUrl ? (this.dataUrl).replace(/-/g, '') + "PageData" : '';
      bannerLocal = this.dataUrl ? (this.dataUrl).replace(/-/g, '') + "BannerData" : '';
    } else {
      apiUrl = this.pageUrl + "-data.json";
      pageDataLocal = this.pageUrl ? (this.pageUrl).replace(/-/g, '') + "PageData" : '';
      bannerLocal = this.pageUrl ? (this.pageUrl).replace(/-/g, '') + "BannerData" : '';
    }

    let pageDataLocalLength = this.sharedService[pageDataLocal] && this.sharedService[pageDataLocal].length > 0 ? this.sharedService[pageDataLocal].length : 0;

    if (pageDataLocalLength == 0) {

      this.dataService.getPageData(apiUrl).subscribe((res: any) => {
        // console.log(res);
        this.banner = res.banner;
        this.sharedService[bannerLocal] = res.banner;
        if (this.detailsPage) {
          let fullData = res.pageData[0].data;
          let singleData = fullData.filter(x => x.pageUrl == this.pageUrl);
          res.pageData[0].data = [];
          res.pageData[0].data = singleData;
          this.pageData = res.pageData;
          // console.log(this.pageData);
        } else {
          this.pageData = res.pageData;
          this.sharedService[pageDataLocal] = res.pageData;
        }
        if (this.dataSource == 'azureApi') {
          if (this.authTokenCalled) {
            this.getAzureApiData();
          } else {
            this.getAuthToken();
          }
        }
      }, errors => {
        console.log(errors);
        this.sharedService[pageDataLocal] = [];
        this.sharedService[bannerLocal] = [];
        this.commonAPi();
      });

    } else {
      this.banner = this.sharedService[bannerLocal];
      this.pageData = this.sharedService[pageDataLocal];
    }

  }

  commonAPi() {
    this.dataService.getPageData("common-data.json").pipe(takeUntil(this.destroy)).subscribe((res: any) => {
      this.banner = res.banner;
      this.pageData = res.pageData;
    }, errors => {
      console.log(errors);
    });
  }

}
