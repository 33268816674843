<!-- layout == 'card-img-title-body -->
<section
  [ngStyle]="{'background-color':cards.bgColor ? cards.bgColor: ''}">
  <div class="bg-image-container" *ngIf="cards.bgImg" [ngStyle]="{'background-image':'url(assets/images/'+ cards.bgImg +')'}"></div>
  <div class="container" [ngClass]="cards.class ? cards.class : ''">
    <div class="text-center title" *ngIf="cards.title">
      <h2 [innerHTML]="cards.title"></h2>
      <hr class="title-divider">
    </div>
    <div *ngIf="cards.layout == 'card-img-title-body'">
      <div class="row align-items-stretch">

        <div class="mb-5"
          [ngClass]="cards.columns == 4 ? 'col-sm-6 col-md-4 col-lg-3': cards.columns == 2 ? 'col-sm-6 col-md-6 mb-4': cards.columns == 1 ? 'col-sm-6 col-md-6 col-lg-12' :'col-sm-6 col-md-6 col-lg-4'"
          *ngFor="let item of cards.items">
          <div class="card shadow h-100">
            <div class="card-img position-relative" *ngIf="(!item.btnLink || item.btnLink == '' || item.btnLink) && item.img">
              <a [routerLink]="item.btnLink">
                <div *ngIf="((cards.showPlayIcon && item.showPlayIcon != false) || (!cards.showPlayIcon && item.showPlayIcon))" class="youtube-play-btn"><i class="fa fa-youtube-play"></i></div>
                <img class="card-img-top zoom" *ngIf="item.img" (load)="item.imageLoaded = true"
                  [src]="'assets/images/'+item.img" alt="{{item.title}}">
                <!-- <img class="card-img-top zoom" *ngIf="!item.img" (load)="item.imageLoaded = true"
                  [src]="'assets/images/default-image.jpg'" alt="{{item.title}}"> -->
                <div class="img-loading d-flex bg-secondary" *ngIf="!item.imageLoaded">
                  <div><i class="fa fa-image"></i></div>
                  <div class="small">Loading...</div>
                </div>
              </a>
            </div>

            <div class="card-img position-relative" *ngIf="item.btnLinkExternal && !item.btnLink && item.img">
              <a [href]="item.btnLinkExternal" target="_blank">
                <div *ngIf="((cards.showPlayIcon && item.showPlayIcon != false) || (!cards.showPlayIcon && item.showPlayIcon))" class="youtube-play-btn"><i class="fa fa-youtube-play"></i></div>
                <img class="card-img-top zoom" *ngIf="item.img" (load)="item.imageLoaded = true"
                  [src]="'assets/images/'+item.img" alt="{{item.title}}">
                <!-- <img class="card-img-top zoom" *ngIf="!item.img" (load)="item.imageLoaded = true"
                  [src]="'assets/images/default-image.jpg'" alt="{{item.title}}"> -->
                <div class="img-loading d-flex bg-secondary" *ngIf="!item.imageLoaded">
                  <div><i class="fa fa-image"></i></div>
                  <div class="small">Loading...</div>
                </div>
              </a>
            </div>
            <div class="card-img position-relative {{!item.body && !item.title && !item.btnTitle ? 'h-100' : ''}}" *ngIf="!item.img && item.bgColor" [ngStyle]="{'background-color':item.bgColor ? item.bgColor: ''}">
                <a [routerLink]="item.btnLink" class="zoom h-100 d-flex align-items-center justify-content-center {{item.class ? item.class : 'text-light'}}">
                  <h3 [innerHTML]="item.linkText"></h3>
                </a>
              </div>
              <div class="card-img position-relative" *ngIf="!item.img && item.icon">
                <a [routerLink]="item.btnLink" class="h-100 d-flex align-items-center justify-content-center display-3 pt-5">
                  <i class="zoom fa fa-{{item.icon}}"></i>
                </a>
              </div>
              <div class="card-img position-relative" *ngIf="!item.img && !item.bgColor && !item.icon">
                <a [routerLink]="item.btnLink" class="h-100 d-flex align-items-center justify-content-center text-light">
                  <img class="card-img-top zoom" *ngIf="!item.img" [src]="'assets/images/default-image.jpg'" alt="{{item.title}}">
                </a>
              </div>

            <div class="card-body" *ngIf="item.body || item.title || item.btnTitle">
                <a *ngIf="!item.img" [routerLink]="item.btnLink">
                    <h5 class="card-title text-center" [ngClass]="item.subTitle ? 'mb-2': 'mt-2 mb-4'" [title]="item.title"
                      [innerHTML]="item.title"></h5>
                  </a>
              <a *ngIf="item.btnLink && item.img" [routerLink]="item.btnLink">
                <h5 class="card-title" [ngClass]="item.subTitle ? 'mb-2': 'mb-0'" [title]="item.title"
                  [innerHTML]="item.title"></h5>
              </a>
              <a *ngIf="item.btnLinkExternal && !item.btnLink && item.img" [href]="item.btnLinkExternal">
                <h5 class="card-title" [ngClass]="item.subTitle ? 'mb-2': 'mt-2 mb-4'" [title]="item.title"
                  [innerHTML]="item.title"></h5>
              </a>
              <h6 *ngIf="item.subTitle" class="small text-secondary mb-3 text-truncate" [innerHTML]="item.subTitle">
              </h6>
              <div class="card-text plain-text" *ngIf="item.body" [innerHTML]="item.body | safeHtml"></div>

              <div class="mt-4 mb-3 text-center" *ngIf="item.btnTitle && (item.btnLink || item.btnLinkExternal)">
                <a [routerLink]="item.btnLink" *ngIf="item.btnLink"><button
                    class="btn btn-primary">{{item.btnTitle}}</button></a>

                <a [href]="item.btnLinkExternal" target="_blank" *ngIf="item.btnLinkExternal && !item.btnLink"><button
                    class="btn btn-primary">{{item.btnTitle}}</button></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>