<!-- <nav #navbarElement class="navbar navbar-expand-lg" [ngClass]="{'fixed-top': type != 'static', 'navbar-dark bg-dark': type == 'static'}"> -->
<nav #navbarElement class="navbar-main navbar fixed-top navbar-expand-lg">
  <div class="container">
    <a class="navbar-brand d-flex align-items-center" routerLink="/" [ngClass]="{'active':currentTab=='home'}"
      (click)="currentActiveTab('home','')">
      <img [src]="'assets/images/logo-yeats-clinic-medicomp-solutions.png'" class="navbar-brand-img shadow mr-2" alt="logo-yeats-clinic-medicomp-solutions"> 
       <!-- <span class="d-inline-block"> 
         <b> Medicomp solutions</b> 
        </span> -->
    </a>
    <button class="navbar-toggler my-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="fa fa-bars"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <ng-container *ngFor="let menu of navbarData">
          <li class="nav-item active w-100" *ngIf="!menu.subMenus" [ngClass]="{'active':currentTab==menu.url}"
            (click)="currentActiveTab(menu.url,'')">
            <a class="nav-link hide-click" [routerLink]="menu.url" [innerHTML]="menu.name"> </a>
          </li>
          <li class="nav-item dropdown" *ngIf="menu.subMenus" [ngClass]="{'active':currentParentTab==menu.url}">

            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" href="#" [innerHTML]="menu.name"></a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <ng-container *ngFor="let subMenu of menu.subMenus">

                <li *ngIf="!subMenu.subMenus">
                  <a class="dropdown-item hide-click" [routerLink]="subMenu.url" *ngIf="subMenu.name != 'Login'"
                    [ngClass]="{'active':currentTab==subMenu.url}" (click)="currentActiveTab(subMenu.url, menu.url)"
                    [innerHTML]="subMenu.name"></a>
                </li>
                <li class="dropdown-submenu" *ngIf="subMenu.subMenus">
                  <a class="dropdown-item dropdown-toggle hide-click" id="navbarDropdown" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" href="#" *ngIf="subMenu.name != 'Login'" [ngClass]="{'active':currentTab==subMenu.url}"
                    (click)="currentActiveTab(subMenu.url, menu.url)" [innerHTML]="subMenu.name"></a>

                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <ng-container *ngFor="let subMenu2 of subMenu.subMenus">
                      <li>
                        <a class="dropdown-item hide-click"
                          [routerLink]="subMenu.url ? subMenu.url + '/' + subMenu2.url : subMenu2.url"
                          [ngClass]="{'active':currentTab==subMenu2.url}"
                          (click)="currentActiveTab(subMenu2.url, menu.url)" [innerHTML]="subMenu2.name"></a>
                      </li>
                    </ng-container>
                  </ul>
                </li>

              </ng-container>
            </ul>

          </li>
        </ng-container>
      </ul>
    </div>


  </div>
</nav>