<app-navbar></app-navbar>
<app-banner *ngIf="banner" [banner]="banner"></app-banner>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-4 pr-3">
                <button class="btn btn-sm btn-primary mb-3 d-block d-md-none" (click)="showAllFeatures()">Show All
                    Features</button>
                <div class="d-none d-md-block">
                    <h2 class="pl-1">All Features</h2>
                    <form [formGroup]="form">
                        <div class="form-group input-group">
                            <input type="text" class="form-control" placeholder="Seacrh..." formControlName="search">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </form>
                    <nav class="bg-light navbar-feature" *ngIf="features">
                        <div class="card border-bottom-0"
                            *ngFor="let item of features.data;let i = index;let last = last" [ngClass]="{'hide': item.items.length == 0 || (item.items | completeSearch: form.get('search').value).length == 0}">
                            <div class="card-header cursor-pointer" [id]="'heading'+i" data-toggle="collapse"
                                [attr.data-target]="'#collapse'+i" aria-expanded="true"
                                [attr.aria-controls]="'collapse'+i">
                                {{item.title}} <span> <span class="small">(</span>{{ (item.items | completeSearch: form.get('search').value).length}}<span class="small">)</span></span> <i class="fa fa-caret-down pull-right"></i></div>

                            <div [id]="'collapse'+i" class="collapse navbar navbar-light" [ngClass]="{'show': i == 0 }"
                                [attr.aria-labelledby]="'heading'+i">
                                <ul class="list-unstyled navbar-nav">
                                    <li class="nav-item" *ngFor="let feature of item.items | completeSearch: form.get('search').value; let j = index">
                                        <a [innerHTML]="feature.title" class="nav-link cursor-pointer"
                                            [routerLink]="'/feature-details/'+feature.url"></a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="col-md-8">
                <div *ngIf="item" class="body-content">
                    <div *ngIf="item.img" class='text-center d-flex align-items-center p-3 h-100'
                        [ngClass]="{'mt-4': item.subTemplate == 'title-body-media-button-vertical','bg-light': !item.crossDesign}">
                        <img [src]="'assets/images/'+item.img" class='img-fluid'
                            alt='Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad'>
                    </div>
                    <h2 [innerHTML]="item.title" class="mb-3"></h2>
                    <div [innerHTML]="item.body"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-promotions *ngIf="promotions" [data]="promotions"></app-promotions>
<app-modal *ngIf="modalData" [data]="modalData"></app-modal>