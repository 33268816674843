import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DataFilterPipe } from '../../pipes/filters.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { DataService } from '../../services/data.service';
import { PaginationControlsComponent } from '../ngx-pagination/ngx-pagination.module';
import { map } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  @Input() gridOptions: any = [];
  @ViewChild("pagination") pagination: PaginationControlsComponent;
  @Output() reload = new EventEmitter<string>();
  fullSearch: any = '';
  text: string = '';
  field: string = '';
  filteredData: any = [];
  config: any;
  assetUrl: string;
  pageUrl: string;
  routeUrl: string;
  sortByAsc: boolean = true;
  sortGridOptions: any;
  sortConfig: any;
  parentRouteUrl: string;
  enableFilter: boolean = false;
  initialItemsPerPage: string;
  itemsPerPage: any;
  fullSearchForm:FormGroup;
  paginationForm: FormGroup;
  columnSearchForm: FormGroup;
  constructor(private datafilter: DataFilterPipe, private route: ActivatedRoute, private router: Router, private data: DataService) { }

  ngOnInit() {

    this.fullSearchForm = new FormGroup({
      fullSearch:new FormControl(),
      liveSearch:new FormControl()
    });

    this.paginationForm = new FormGroup({
      itemsPerPage:new FormControl()
    });

    this.columnSearchForm = new FormGroup({});

    this.gridOptions.colDefs.forEach(element => {
      this.columnSearchForm.addControl(element.name, new FormControl(''));
    });

    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
    };
    this.config.itemsPerPage = this.gridOptions.itemsPerPage ? this.gridOptions.itemsPerPage : 10;
    this.itemsPerPage = this.config.itemsPerPage;

    this.route.queryParamMap.pipe(map(params => params.get('page'))).subscribe(page => this.config.currentPage = page);

    if (typeof (this.gridOptions.colDefs) === 'undefined') {
      this.gridOptions.colDefs = [];
      Object.keys(this.gridOptions.data[0]).forEach((v, k) => {
        let item = { 'name': v, displayName: v.split(/(?=[A-Z])/).join(' ') }
        this.gridOptions.colDefs.push(item);
      })
    }

    this.getFilteredData(this.field, this.text);

    this.pageUrl = this.router.url;
    this.routeUrl = this.pageUrl.split("/")[this.pageUrl.split("/").length - 1].split("?")[0];
    this.parentRouteUrl = this.pageUrl.split("/")[0];
    if (this.pageUrl.split("/").length == 3) {
      this.parentRouteUrl = this.pageUrl.split("/")[1];
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  ngAfterContentInit(){
    if(this.gridOptions.searchFields && this.gridOptions.searchFields.length > 0){
    this.gridOptions.searchFields.forEach(element => {
      this.fullSearchForm.addControl(element.controlName, new FormControl(''));
    });
  }
}

  pageChange(newPage: number) {
    this.filteredData.forEach((v, k) => {
      v.selected = false
    });

    if (this.routeUrl != this.parentRouteUrl) {
      let paginateUrl = this.parentRouteUrl + "/" + this.routeUrl;
      this.router.navigate([paginateUrl], { queryParams: { page: newPage } });
    } else {
      this.router.navigate([this.routeUrl], { queryParams: { page: newPage } });
    }
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

  }

  sortTable(parm, isNumber) {
    if ((isNumber == "undefined" || typeof isNumber != "boolean") && parm != 'date') {
      var parsedVal = parseInt(this.gridOptions.data[0][parm])
      if (!isNaN(parsedVal)) {
        isNumber = true;
      } else {
        isNumber = false;
      }
    }

    if (this.sortByAsc == true) {
      this.sortByAsc = false;
      this.filteredData.sort((a, b) => {
        if (typeof a[parm] != 'object')
          if (isNumber)
            return a[parm] - (b[parm]);
          else
            return a[parm].localeCompare(b[parm]);
      });
    } else {
      this.sortByAsc = true;
      this.filteredData.sort((a, b) => {
        if (typeof a[parm] != 'object')
          if (isNumber)
            return b[parm] - (a[parm]);
          else
            return b[parm].localeCompare(a[parm]);
      });
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  getFilteredData(field, txt) {
    var filterFields = [{}];
    if (field != '') {
      filterFields[0][field] = txt;
    }

    if (field == '' && txt == '') {
      filterFields = [];
    }

    this.filteredData = this.datafilter.transform(this.gridOptions.data, filterFields);
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  changeItemsPerPage(itemsPerPage) {
    if (itemsPerPage) {
      this.config.itemsPerPage = itemsPerPage
      if (itemsPerPage >= this.filteredData.length)
        this.pageChange(1);
    }else{
      this.config.itemsPerPage = this.gridOptions.itemsPerPage ? this.gridOptions.itemsPerPage : this.config.itemsPerPage;
    }
  }

  liveDbSearch(){
    console.log(this.fullSearchForm.get("fullSearch").value)
  }

}
