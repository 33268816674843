<div class="modal modal-zoom" #modalPopup id="largeModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="data.class ? data.class : ''">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content shadow-xl">
            <button type="button" class="close close-float d-none d-sm-block" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body p-0">
                <div class="embed-responsive embed-responsive-video" *ngIf="data.template == 'ytVideo'">
                    <iframe class="embed-responsive-item"
                        [src]="'https://www.youtube.com/embed/'+data.videoId+'?rel=0&modestbranding=0&showinfo=0' | safeUrl"
                        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
                <div *ngIf="data.template == 'title-body-media-button'">
                    <div class="modal-header">
                        <div class="col-12 p-0">
                            <h2 class="pl-1">All Features</h2>
                            <form [formGroup]="form">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Seacrh..."
                                        formControlName="search">
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <nav class="bg-light navbar-feature px-2" *ngIf="data.items">
                        <div class="card border-bottom-0"
                            *ngFor="let item of data.items.data;let i = index;let last = last">
                            <div class="card-header cursor-pointer" [id]="'heading'+i" data-toggle="collapse"
                                [attr.data-target]="'#collapse'+i" aria-expanded="true"
                                [attr.aria-controls]="'collapse'+i">
                                {{item.title}} <span> <span
                                        class="small">(</span>{{ (item.items | completeSearch: form.get('search').value).length}}<span
                                        class="small">)</span></span> <i class="fa fa-caret-down pull-right"></i></div>

                            <div [id]="'collapse'+i" class="collapse navbar navbar-light" [ngClass]="{'show': i == 0 }"
                                [attr.aria-labelledby]="'heading'+i">
                                <ul class="list-unstyled navbar-nav">
                                    <li class="nav-item"
                                        *ngFor="let feature of item.items | completeSearch: form.get('search').value; let j = index">
                                        <a [innerHTML]="feature.title" class="nav-link cursor-pointer"
                                            [routerLink]="'/feature-details/'+feature.url"></a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="modal-footer border-0 p-1 pb-sm-2 px-sm-2">
                <button type="button" class="btn btn-primary shadow" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>