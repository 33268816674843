import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PostsComponent } from './components/posts/posts.component';
import { ContactComponent } from './components/contact/contact.component';
import { FeaturesComponent } from './components/features/features.component';
import { HospitalInformationComponent } from './components/hospital-information/hospital-information.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: "ehr-emr/:url", component: PostsComponent },
  { path: "ehr-emr/large-practice", component: PostsComponent },
  { path: "ehr-emr/small-practice", component: PostsComponent },
  { path: "ehr-emr/speciality-ehr", component: PostsComponent },
  { path: "partners/become-partner", component: PostsComponent },
  { path: "api", component: PostsComponent },
  { path: "partners/apple-mobility-partner", component: PostsComponent },
  { path: "partners/university-ehr-emr", component: PostsComponent },
  { path: "partners/affiliates", component: PostsComponent },

  { path: "electronic-health-record-ehr", component: PostsComponent },
  { path: "practice-management", component: PostsComponent },
  { path: "medical-billing", component: PostsComponent },
  { path: "revenue-cycle-management", component: PostsComponent },
  { path: "patient-portal", component: PostsComponent },
  { path: "patient-inventory-management", component: PostsComponent },
  { path: "mobile-ipad-ehr-app", component: PostsComponent },
  { path: "scheduling", component: PostsComponent },
  { path: "clinical-support-services", component: PostsComponent },
  { path: "prescription-management", component: PostsComponent },
  { path: "highlights-of-yeats-clinical", component: PostsComponent },
  { path: "hospital-information", component: HospitalInformationComponent },

  { path: "macra-mips", component: PostsComponent},
  { path: "onc-certification", component: PostsComponent },
  { path: "resources", component: PostsComponent },
  { path: "resources/:url", component: PostsComponent },
  { path: "resources/:url/:url", component: PostsComponent, data:{"jsonFile":"resources-detailes", "detailsPage":true} },
  // { path: "resources/ehr-training-videos/:url", component: PostsComponent },
  { path: "medical-form-templates", component: PostsComponent, data:{"dataSource":"azureApi"} },
  { path: "medical-billing-code-lookup", component: PostsComponent, data:{"dataSource":"azureApi"} },
  { path: "medical-billing-codes/:url", component: PostsComponent, data:{"dataSource":"azureApi"} },
  { path: "plans-and-pricing", component: PostsComponent },
  { path: "plans-and-pricing/:url", component: PostsComponent },
  { path: "plans-and-pricing/plan-details", component: PostsComponent },
  { path: "support", component: PostsComponent },
  { path: "colleague-referral", component: PostsComponent },
  { path: "features", component: FeaturesComponent },
  { path: "features/:url", component: FeaturesComponent },
  { path: "feature-details", component: FeaturesComponent },
  { path: "feature-details/:url", component: FeaturesComponent },
  { path: "about-us", component: PostsComponent },
  { path: "contact-us", component: ContactComponent },
  
  { path: 'sign-up', component: PostsComponent, data:{"jsonFile":"sign-up"}  },
  { path: 'ehr-pricing-quote', component: PostsComponent, data:{"jsonFile":"sign-up"} },
  { path: 'schedule-demo', component: PostsComponent, data:{"jsonFile":"sign-up"}  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
