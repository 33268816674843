import { Component, OnInit, Input } from '@angular/core';
// import { SharedService } from 'src/app/services/shared.service';
declare let $: any;

@Component({
  selector: 'app-title-body-media-button',
  templateUrl: './title-body-media-button.component.html',
  styleUrls: ['./title-body-media-button.component.scss']
})
export class TitleBodyMediaButtonComponent implements OnInit {

  @Input() items: any = [];
  modalData: {};

  constructor() { }

  ngOnInit() {
  }

  setModalPopupData(videoId) {
    this.modalData = { 'template': 'ytVideo', 'videoId': videoId };
    // $("#largeModal").modal("show");
  }
}
