<section *ngFor="let item of items; let index = index" class="{{item.sectionClass}}" [ngStyle]="{'background-color':!item.crossDesign && item.bgColor ? item.bgColor: ''}" [ngClass]="{'py-50': item.crossDesign}">
    <div class="cross-design" *ngIf="item.crossDesign" [ngStyle]="{'background-color':item.bgColor ? item.bgColor: ''}"></div>
    <div class="bg-image-container" *ngIf="item.bgImg"
        [ngStyle]="{'background-image':'url(assets/images/'+ item.bgImg +')'}"></div>
    <div [ngClass]="item.class ? item.class : ''">
        <div class="text-center position-relative" *ngIf="item.title">
            <h2 [innerHTML]="item.title"></h2>
            <hr class="title-divider">
        </div>

        <div class="row align-items-stretch content-row">
            <div class="overflow-hidden {{item.subTemplate == 'title-body-media-button-vertical' ? 'col-md-12' : 'col-md-6'}}"
                [ngClass]="(item.bodyOrder == 1 || (!item.bodyOrder && (index % 2 == 0))) ? 'order-1' : 'order-1 order-md-0'">
                <div class="{{item.subTemplate == 'title-body-media-button-vertical' ? 'mt-4': ''}}" [innerHTML]="item.body"></div>
                <p class="mt-4 mb-md-3"
                    [ngClass]="{'text-md-left': item.subTemplate == 'title-body-media-button-horizontal' }"
                    *ngIf="item.btnTitle">
                    <a class="btn"
                        [ngClass]="item.class.includes('text-white') || item.class.includes('text-light') ? 'btn-light' : 'btn-primary'"
                        *ngIf="!item.btnUrlExternal" [routerLink]="item.btnUrl" [innerHTML]="item.btnTitle"></a>
                    <a class="btn"
                        [ngClass]="item.class.includes('text-white') || item.class.includes('text-light') ? 'btn-light' : 'btn-primary'"
                        *ngIf="item.btnUrlExternal" [href]="item.btnUrlExternal" target="_blank"
                        [innerHTML]="item.btnTitle"></a>
                </p>
            </div>
            <div class="overflow-hidden {{item.subTemplate == 'title-body-media-button-vertical' ? 'col-md-12' : 'mb-4 mb-md-0 col-md-6'}}"
                [ngClass]="{'align-self-baseline': item.body2}">
                <div *ngIf="item.body2" [innerHTML]="item.body2"></div>
                <div *ngIf="item.img" class='text-center d-flex align-items-center p-3 h-100'
                    [ngClass]="{'mt-4': item.subTemplate == 'title-body-media-button-vertical','bg-light': !item.crossDesign}">
                    <img [src]="'assets/images/'+item.img" class='img-fluid'
                        alt='Form builder and free draw capabilities for Medicomp Solutions EHR on macbook and iPad'>
                </div>
                <div class="card-img position-relative" *ngIf="item.ytVideo" (click)="setModalPopupData(item.ytVideo)" data-toggle="modal" data-target="#largeModal">
                    <div class="youtube-play-btn"><i class="fa fa-youtube-play"></i></div>
                    <img class="img-fluid m-auto zoom" *ngIf="item.ytVideo && item.ytVideo != ''"
                        [src]="'https://img.youtube.com/vi/'+ item.ytVideo +'/maxresdefault-image.jpg'"
                        alt="{{item.name}}">
                </div>

                <div *ngIf="item.video" class="embed-responsive responsive-video devices-video">
                    <p *ngIf="!item.video.includes('.mp4')">Looking for .mp4 format</p>
                    <video class="embed-responsive-item" controls>
                        <source [src]="'assets/videos/'+item.video | safeUrl" type="video/mp4">
                    </video>
                </div>
                <div *ngIf="item.pdfFile" class="embed-responsive embed-responsive-video devices-video">
                    <iframe class="embed-responsive-item"
                        [src]="'assets/data/docs/'+item.pdfFile | safeUrl" allowfullscreen></iframe>
                </div>
                <div *ngIf="item.pdfFile" class="mt-4">
                    <a [href]="'assets/data/docs/'+item.pdfFile | safeUrl" class="btn btn-primary"
                        target="_blank" download="">Download</a>
                </div>
            </div>

        </div>
    </div>
</section>

<app-modal *ngIf="modalData && modalData != ''" [data]="modalData"></app-modal>