<section
    [ngStyle]="{'background-color':data.bgColor ? data.bgColor: ''}">
    <div class="bg-image-container" *ngIf="data.bgImg" [ngStyle]="{'background-image':'url(assets/images/'+ data.bgImg +')'}"></div>
    <div class="container" [ngClass]="data.class ? data.class : ''">
        <div class="text-center title" *ngIf="data.title">
            <h2 [innerHTML]="data.title"></h2>
            <hr class="title-divider">
        </div>
        <div id="accordion">
            <div class="card" *ngFor="let item of data.items;let i = index;let last = last" [ngClass]="{'mb-3': !last}">
                <div class="card-header cursor-pointer" [id]="'heading'+i" data-toggle="collapse" [attr.data-target]="'#collapse'+i"
                aria-expanded="true" [attr.aria-controls]="'collapse'+i"><span *ngIf="data.enableSerialNumber">{{i+1 +". "}}</span> {{item.title}} <i class="fa fa-caret-down pull-right"></i></div>

                <div [id]="'collapse'+i" class="collapse" [ngClass]="{'show': i == 0 }" [attr.aria-labelledby]="'heading'+i" data-parent="#accordion">
                    <div class="card-body" [innerHTML]="item.body"></div>
                </div>
            </div>
        </div>
    </div>
</section>