import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
declare let $: any;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() data: any;
  form: any;

  constructor() { }

  ngOnInit() {
    let self = this;
    $("#largeModal").on('hidden.bs.modal', function (e) {
      // $("#largeModal iframe").each(function () {
      //   $(this).attr("src", $(this).attr("src"));
      // });
      self.data = {};
    });

    this.form = new FormGroup({
      search: new FormControl('')
    })
  }

}
