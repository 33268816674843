<section class="bg-light">
    <div class="wrap leadership">
        <div class="container">
            <div class="text-center title mb-3">
                <h2>Our Team</h2>
                <hr class="title-divider">
            </div>
            <div class="row text-center text-light users">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 p-3" *ngFor="let item of data.items">
                    <div class="card text-center p-4 bg-primary h-100 d-flex">
                        <img class="img-fluid rounded-circle m-auto shadow"
                            [src]="'/assets/images/users/'+item.img" [alt]="item.name">
                        <h5 class="mt-3" [innerHTML]="item.name"></h5>
                        <div [innerHTML]="item.position"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>