import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { slidetoLeftBounced } from '../animations';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  animations: [slidetoLeftBounced]
})
export class AlertMessageComponent implements OnInit {

  webstackId: number;
  webstackWaitTime = 15; // in seconds
  messageDetails: any;
  constructor(public sharedData: SharedService) { }

  ngOnInit() {
    setInterval(() => {
      var timeStamp = Math.floor(Date.now() / 1000);
      if (this.sharedData && this.sharedData.alertMessage.length > 0) {
        this.sharedData.alertMessage.forEach((v: any, k: number) => {
          if (v.timeStamp + this.webstackWaitTime < timeStamp) {
            this.sharedData.alertMessage.splice(k, 1);
          }
        });
      }
    })
  }

  removeAlert(index: number) {
    this.sharedData.alertMessage.splice(index, 1);
  }

  showMoreErrorDetails(message){
    this.sharedData.alertshowMore = true;
    this.messageDetails = message;
    
  }

}
