import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  template: `
  <app-navbar type="static"></app-navbar>
  <div class="jumbotron py-5">
  <div class="container">
      <h1 class="py-5">
          Page not found
      </h1>
      </div>
      </div>
  `,
  styles: []
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
